export const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440
}
export const layoutConstant = {
  topbarHeight: 40,
  grocerySidenavWidth: 280,
  containerWidth: 1200,
  mobileNavHeight: 64,
  headerHeight: 80,
  mobileHeaderHeight: 64
}

//export const url = ''
// export const url = 'http://localhost:8090/'
// export const url = 'http://31.207.35.98:8090/'
// export const url = 'http://www.soluchange.pategou.com:8089/'
export const url = 'https://www.soluchange.com:8090/'
export const baseUrl = 'https://www.soluchange.com'

const currencies = [
  {
    "country": "Afghanistan",
    "countryFr": "Afghanistan",
    "name": "Afghan afghani",
    "isoCode": "AFN"
  },
  {
    "country": "Akrotiri and Dhekelia (UK)",
    "countryFr": "Akrotiri et Dhekelia (UK)",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Aland Islands (Finland)",
    "countryFr": "Îles Åland (Finlande)",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Albania",
    "countryFr": "Albanie",
    "name": "Albanian lek",
    "isoCode": "ALL"
  },
  {
    "country": "Algeria",
    "countryFr": "Algérie",
    "name": "Algerian dinar",
    "isoCode": "DZD"
  },
  {
    "country": "American Samoa (USA)",
    "countryFr": "Samoa américaines (USA)",
    "name": "United States dollar",
    "isoCode": "USD"
  },
  {
    "country": "Andorra",
    "countryFr": "Andorre",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Angola",
    "countryFr": "Angola",
    "name": "Angolan kwanza",
    "isoCode": "AOA"
  },
  {
    "country": "Anguilla (UK)",
    "countryFr": "Anguilla (UK)",
    "name": "East Caribbean dollar",
    "isoCode": "XCD"
  },
  {
    "country": "Antigua and Barbuda",
    "countryFr": "Antigua-et-Barbuda",
    "name": "East Caribbean dollar",
    "isoCode": "XCD"
  },
  {
    "country": "Argentina",
    "countryFr": "Argentine",
    "name": "Argentine peso",
    "isoCode": "ARS"
  },
  {
    "country": "Armenia",
    "countryFr": "Arménie",
    "name": "Armenian dram",
    "isoCode": "AMD"
  },
  {
    "country": "Aruba (Netherlands)",
    "countryFr": "Aruba (Pays-Bas)",
    "name": "Aruban florin",
    "isoCode": "AWG"
  },
  {
    "country": "Ascension Island (UK)",
    "countryFr": "Île de l'Ascension (UK)",
    "name": "Saint Helena pound",
    "isoCode": "SHP"
  },
  {
    "country": "Australia",
    "countryFr": "Australie",
    "name": "Australian dollar",
    "isoCode": "AUD"
  },
  {
    "country": "Austria",
    "countryFr": "Autriche",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Azerbaijan",
    "countryFr": "Azerbaïdjan",
    "name": "Azerbaijan manat",
    "isoCode": "AZN"
  },
  {
    "country": "Bahamas",
    "countryFr": "Bahamas",
    "name": "Bahamian dollar",
    "isoCode": "BSD"
  },
  {
    "country": "Bahrain",
    "countryFr": "Bahreïn",
    "name": "Bahraini dinar",
    "isoCode": "BHD"
  },
  {
    "country": "Bangladesh",
    "countryFr": "Bangladesh",
    "name": "Bangladeshi taka",
    "isoCode": "BDT"
  },
  {
    "country": "Barbados",
    "countryFr": "Barbade",
    "name": "Barbadian dollar",
    "isoCode": "BBD"
  },
  {
    "country": "Belarus",
    "countryFr": "Biélorussie",
    "name": "Belarusian ruble",
    "isoCode": "BYN"
  },
  {
    "country": "Belgium",
    "countryFr": "Belgique",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Belize",
    "countryFr": "Belize",
    "name": "Belize dollar",
    "isoCode": "BZD"
  },
  {
    "country": "Benin",
    "countryFr": "Bénin",
    "name": "West African CFA franc",
    "isoCode": "XOF"
  },
  {
    "country": "Bermuda (UK)",
    "countryFr": "Bermudes (UK)",
    "name": "Bermudian dollar",
    "isoCode": "BMD"
  },
  {
    "country": "Bhutan",
    "countryFr": "Bhoutan",
    "name": "Bhutanese ngultrum",
    "isoCode": "BTN"
  },
  {
    "name": "Kyat",
    "country": "Myanmar",
    "countryFr": "Birmanie",
    "isoCode": "MMK"
  },
  {
    "country": "Bolivia",
    "countryFr": "Bolivie",
    "name": "Bolivian boliviano",
    "isoCode": "BOB"
  },
  {
    "country": "Bonaire (Netherlands)",
    "countryFr": "Bonaire (Pays-Bas)",
    "name": "United States dollar",
    "isoCode": "USD"
  },
  {
    "country": "Bosnia and Herzegovina",
    "countryFr": "Bosnie-Herzégovine",
    "name": "Bosnia and Herzegovina convertible mark",
    "isoCode": "BAM"
  },
  {
    "country": "Botswana",
    "countryFr": "Botswana",
    "name": "Botswana pula",
    "isoCode": "BWP"
  },
  {
    "country": "Brazil",
    "countryFr": "Brésil",
    "name": "Brazilian real",
    "isoCode": "BRL"
  },
  {
    "country": "British Indian Ocean Territory (UK)",
    "countryFr": "Territoire britannique de l'océan Indien (UK)",
    "name": "United States dollar",
    "isoCode": "USD"
  },
  {
    "country": "British Virgin Islands (UK)",
    "countryFr": "Îles Vierges britanniques (UK)",
    "name": "United States dollar",
    "isoCode": "USD"
  },
  {
    "country": "Brunei",
    "countryFr": "Brunei",
    "name": "Brunei dollar",
    "isoCode": "BND"
  },
  {
    "country": "Bulgaria",
    "countryFr": "Bulgarie",
    "name": "Bulgarian lev",
    "isoCode": "BGN"
  },
  {
    "country": "Burkina Faso",
    "countryFr": "Burkina Faso",
    "name": "West African CFA franc",
    "isoCode": "XOF"
  },
  {
    "country": "Burundi",
    "countryFr": "Burundi",
    "name": "Burundi franc",
    "isoCode": "BIF"
  },
  {
    "country": "Cabo Verde",
    "countryFr": "Cap-Vert",
    "name": "Cabo Verdean escudo",
    "isoCode": "CVE"
  },
  {
    "country": "Cambodia",
    "countryFr": "Cambodge",
    "name": "Cambodian riel",
    "isoCode": "KHR"
  },
  {
    "country": "Cameroon",
    "countryFr": "Cameroun",
    "name": "Central African CFA franc",
    "isoCode": "XAF"
  },
  {
    "country": "Canada",
    "countryFr": "Canada",
    "name": "Canadian dollar",
    "isoCode": "CAD"
  },
  {
    "country": "Caribbean Netherlands (Netherlands)",
    "countryFr": "Pays-Bas caribéens (Pays-Bas)",
    "name": "United States dollar",
    "isoCode": "USD"
  },
  {
    "country": "Cayman Islands (UK)",
    "countryFr": "Îles Caïmans (UK)",
    "name": "Cayman Islands dollar",
    "isoCode": "KYD"
  },
  {
    "country": "Central African Republic",
    "countryFr": "République centrafricaine",
    "name": "Central African CFA franc",
    "isoCode": "XAF"
  },
  {
    "country": "Chad",
    "countryFr": "Tchad",
    "name": "Central African CFA franc",
    "isoCode": "XAF"
  },
  {
    "country": "Chatham Islands (New Zealand)",
    "countryFr": "Îles Chatham (Nouvelle-Zélande)",
    "name": "New Zealand dollar",
    "isoCode": "NZD"
  },
  {
    "country": "Chile",
    "countryFr": "Chili",
    "name": "Chilean peso",
    "isoCode": "CLP"
  },
  {
    "country": "China",
    "countryFr": "Chine",
    "name": "Chinese yuan",
    "isoCode": "CNY"
  },
  {
    "name": "South Korean won",
    "country": "South Korea",
    "countryFr": "Corée du Sud",
    "isoCode": "KRW"
  },
  {
    "name": "North Korean won",
    "country": "North Korea",
    "countryFr": "Corée du Nord",
    "isoCode": "KPW"
  },
  {
    "country": "Christmas Island (Australia)",
    "countryFr": "Île Christmas (Australie)",
    "name": "Australian dollar",
    "isoCode": "AUD"
  },
  {
    "country": "Cocos (Keeling) Islands (Australia)",
    "countryFr": "Îles Cocos (Keeling) (Australie)",
    "name": "Australian dollar",
    "isoCode": "AUD"
  },
  {
    "country": "Colombia",
    "countryFr": "Colombie",
    "name": "Colombian peso",
    "isoCode": "COP"
  },
  {
    "country": "Comoros",
    "countryFr": "Comores",
    "name": "Comorian franc",
    "isoCode": "KMF"
  },
  {
    "country": "Congo, Democratic Republic of the",
    "countryFr": "Congo, République démocratique du",
    "name": "Congolese franc",
    "isoCode": "CDF"
  },
  {
    "country": "Congo, Republic of the",
    "countryFr": "Congo, République du",
    "name": "Central African CFA franc",
    "isoCode": "XAF"
  },
  {
    "name": "Central African CFA franc",
    "country": "Republic of the Congo",
    "countryFr": "République du Congo",
    "isoCode": "XAF"
  },
  {
    "name": "Congolese franc",
    "country": "Democratic Republic of the Congo",
    "countryFr": "République démocratique du Congo",
    "isoCode": "CDF"
  },
  {
    "name": "West African CFA franc",
    "country": "Ivory Coast",
    "countryFr": "Côte d’Ivoire",
    "isoCode": "XOF"
  },
  {
    "country": "Cook Islands (New Zealand)",
    "countryFr": "Îles Cook (Nouvelle-Zélande)",
    "name": "Cook Islands dollar",
    "isoCode": "NZD"
  },
  {
    "country": "Costa Rica",
    "countryFr": "Costa Rica",
    "name": "Costa Rican colón",
    "isoCode": "CRC"
  },
  {
    "country": "Croatia",
    "countryFr": "Croatie",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Cuba",
    "countryFr": "Cuba",
    "name": "Cuban peso",
    "isoCode": "CUP"
  },
  {
    "country": "Curaçao (Netherlands)",
    "countryFr": "Curaçao (Pays-Bas)",
    "name": "Netherlands Antillean guilder",
    "isoCode": "ANG"
  },
  {
    "country": "Cyprus",
    "countryFr": "Chypre",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Czechia",
    "countryFr": "Tchéquie",
    "name": "Czech koruna",
    "isoCode": "CZK"
  },
  {
    "country": "Denmark",
    "countryFr": "Danemark",
    "name": "Danish krone",
    "isoCode": "DKK"
  },
  {
    "country": "Djibouti",
    "countryFr": "Djibouti",
    "name": "Djiboutian franc",
    "isoCode": "DJF"
  },
  {
    "country": "Dominica",
    "countryFr": "Dominique",
    "name": "East Caribbean dollar",
    "isoCode": "XCD"
  },
  {
    "country": "Dominican Republic",
    "countryFr": "République dominicaine",
    "name": "Dominican peso",
    "isoCode": "DOP"
  },
  {
    "country": "East Timor",
    "countryFr": "Timor oriental",
    "name": "United States dollar",
    "isoCode": "USD"
  },
  {
    "country": "Ecuador",
    "countryFr": "Équateur",
    "name": "United States dollar",
    "isoCode": "USD"
  },
  {
    "country": "Egypt",
    "countryFr": "Égypte",
    "name": "Egyptian pound",
    "isoCode": "EGP"
  },
  {
    "name": "United States dollar",
    "country": "El Salvador",
    "countryFr": "Salvador",
    "isoCode": "USD"
  },
  {
    "country": "El Salvador",
    "countryFr": "El Salvador",
    "name": "United States dollar",
    "isoCode": "USD"
  },
  {
    "country": "Equatorial Guinea",
    "countryFr": "Guinée équatoriale",
    "name": "Central African CFA franc",
    "isoCode": "XAF"
  },
  {
    "country": "Eritrea",
    "countryFr": "Érythrée",
    "name": "Eritrean nakfa",
    "isoCode": "ERN"
  },
  {
    "country": "Estonia",
    "countryFr": "Estonie",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Eswatini",
    "countryFr": "Eswatini",
    "name": "Swazi lilangeni",
    "isoCode": "SZL"
  },
  {
    "country": "Ethiopia",
    "countryFr": "Éthiopie",
    "name": "Ethiopian birr",
    "isoCode": "ETB"
  },
  {
    "country": "Falkland Islands (UK)",
    "countryFr": "Îles Malouines (UK)",
    "name": "Falkland Islands pound",
    "isoCode": "FKP"
  },
  {
    "country": "Faroe Islands (Denmark)",
    "countryFr": "Îles Féroé (Danemark)",
    "name": "Danish krone",
    "isoCode": "DKK"
  },
  {
    "country": "Fiji",
    "countryFr": "Fidji",
    "name": "Fijian dollar",
    "isoCode": "FJD"
  },
  {
    "country": "Finland",
    "countryFr": "Finlande",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "France",
    "countryFr": "France",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "French Guiana (France)",
    "countryFr": "Guyane française (France)",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "French Polynesia (France)",
    "countryFr": "Polynésie française (France)",
    "name": "CFP franc",
    "isoCode": "XPF"
  },
  {
    "country": "Gabon",
    "countryFr": "Gabon",
    "name": "Central African CFA franc",
    "isoCode": "XAF"
  },
  {
    "country": "Gambia",
    "countryFr": "Gambie",
    "name": "Gambian dalasi",
    "isoCode": "GMD"
  },
  {
    "country": "Georgia",
    "countryFr": "Géorgie",
    "name": "Georgian lari",
    "isoCode": "GEL"
  },
  {
    "country": "Germany",
    "countryFr": "Allemagne",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Ghana",
    "countryFr": "Ghana",
    "name": "Ghanaian cedi",
    "isoCode": "GHS"
  },
  {
    "country": "Gibraltar (UK)",
    "countryFr": "Gibraltar (UK)",
    "name": "Gibraltar pound",
    "isoCode": "GIP"
  },
  {
    "country": "Greece",
    "countryFr": "Grèce",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Greenland (Denmark)",
    "countryFr": "Groenland (Danemark)",
    "name": "Danish krone",
    "isoCode": "DKK"
  },
  {
    "country": "Grenada",
    "countryFr": "Grenade",
    "name": "East Caribbean dollar",
    "isoCode": "XCD"
  },
  {
    "country": "Guadeloupe (France)",
    "countryFr": "Guadeloupe (France)",
    "name": "European euro",
    "isoCode": "EUR"
  },
  {
    "country": "Guam (USA)",
    "countryFr": "Guam (USA)",
    "name": "United States dollar",
    "isoCode": "USD"
  },
  {
    "country": "Guatemala",
    "countryFr": "Guatemala",
    "name": "Guatemalan quetzal",
    "isoCode": "GTQ"
  },
  {
    "country": "Guernsey (UK)",
    "countryFr": "Guernesey (UK)",
    "name": "Guernsey pound",
    "isoCode": "GGP"
  },
  {
    "country": "Guinea",
    "countryFr": "Guinée",
    "name": "Guinean franc",
    "nameFr": "Franc guinéen",
    "isoCode": "GNF"
  },
  {
    "country": "Guinea-Bissau",
    "countryFr": "Guinée-Bissau",
    "name": "West African CFA franc",
    "nameFr": "Franc CFA de l'Afrique de l'Ouest",
    "isoCode": "XOF"
  },
  {
    "country": "Guyana",
    "countryFr": "Guyane",
    "name": "Guyanese dollar",
    "nameFr": "Dollar guyanien",
    "isoCode": "GYD"
  },
  {
    "country": "Haiti",
    "countryFr": "Haïti",
    "name": "Haitian gourde",
    "nameFr": "Gourde haïtienne",
    "isoCode": "HTG"
  },
  {
    "country": "Honduras",
    "countryFr": "Honduras",
    "name": "Honduran lempira",
    "nameFr": "Lempira hondurien",
    "isoCode": "HNL"
  },
  {
    "country": "Hong Kong (China)",
    "countryFr": "Hong Kong (Chine)",
    "name": "Hong Kong dollar",
    "nameFr": "Dollar de Hong Kong",
    "isoCode": "HKD"
  },
  {
    "country": "Hungary",
    "countryFr": "Hongrie",
    "name": "Hungarian forint",
    "nameFr": "Forint hongrois",
    "isoCode": "HUF"
  },
  {
    "country": "Iceland",
    "countryFr": "Islande",
    "name": "Icelandic krona",
    "nameFr": "Couronne islandaise",
    "isoCode": "ISK"
  },
  {
    "country": "India",
    "countryFr": "Inde",
    "name": "Indian rupee",
    "nameFr": "Roupie indienne",
    "isoCode": "INR"
  },
  {
    "country": "Indonesia",
    "countryFr": "Indonésie",
    "name": "Indonesian rupiah",
    "nameFr": "Roupie indonésienne",
    "isoCode": "IDR"
  },
  {
    "country": "International Monetary Fund (IMF)",
    "countryFr": "Fonds Monétaire International (FMI)",
    "name": "SDR (Special Drawing Right)",
    "nameFr": "Droits de tirage spéciaux (DTS)",
    "isoCode": "XDR"
  },
  {
    "country": "Iran",
    "countryFr": "Iran",
    "name": "Iranian rial",
    "nameFr": "Rial iranien",
    "isoCode": "IRR"
  },
  {
    "country": "Iraq",
    "countryFr": "Irak",
    "name": "Iraqi dinar",
    "nameFr": "Dinar irakien",
    "isoCode": "IQD"
  },
  {
    "country": "Ireland",
    "countryFr": "Irlande",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Isle of Man (UK)",
    "countryFr": "Île de Man (Royaume-Uni)",
    "name": "Manx pound",
    "nameFr": "Livre mannoise",
    "isoCode": "IMP"
  },
  {
    "country": "Israel",
    "countryFr": "Israël",
    "name": "Israeli new shekel",
    "nameFr": "Nouveau shekel israélien",
    "isoCode": "ILS"
  },
  {
    "country": "Italy",
    "countryFr": "Italie",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Jamaica",
    "countryFr": "Jamaïque",
    "name": "Jamaican dollar",
    "nameFr": "Dollar jamaïcain",
    "isoCode": "JMD"
  },
  {
    "country": "Japan",
    "countryFr": "Japon",
    "name": "Japanese yen",
    "nameFr": "Yen japonais",
    "isoCode": "JPY"
  },
  {
    "country": "Jersey (UK)",
    "countryFr": "Jersey (Royaume-Uni)",
    "name": "Jersey pound",
    "nameFr": "Livre de Jersey",
    "isoCode": "JEP"
  },
  {
    "country": "Jordan",
    "countryFr": "Jordanie",
    "name": "Jordanian dinar",
    "nameFr": "Dinar jordanien",
    "isoCode": "JOD"
  },
  {
    "country": "Kazakhstan",
    "countryFr": "Kazakhstan",
    "name": "Kazakhstani tenge",
    "nameFr": "Tenge kazakh",
    "isoCode": "KZT"
  },
  {
    "country": "Kenya",
    "countryFr": "Kenya",
    "name": "Kenyan shilling",
    "nameFr": "Shilling kenyan",
    "isoCode": "KES"
  },
  {
    "country": "Kiribati",
    "countryFr": "Kiribati",
    "name": "Australian dollar",
    "nameFr": "Dollar australien",
    "isoCode": "AUD"
  },
  {
    "country": "Kosovo",
    "countryFr": "Kosovo",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Kuwait",
    "countryFr": "Koweït",
    "name": "Kuwaiti dinar",
    "nameFr": "Dinar koweïtien",
    "isoCode": "KWD"
  },
  {
    "country": "Kyrgyzstan",
    "countryFr": "Kirghizistan",
    "name": "Kyrgyzstani som",
    "nameFr": "Som kirghize",
    "isoCode": "KGS"
  },
  {
    "country": "Laos",
    "countryFr": "Laos",
    "name": "Lao kip",
    "nameFr": "Kip laotien",
    "isoCode": "LAK"
  },
  {
    "country": "Latvia",
    "countryFr": "Lettonie",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Lebanon",
    "countryFr": "Liban",
    "name": "Lebanese pound",
    "nameFr": "Livre libanaise",
    "isoCode": "LBP"
  },
  {
    "country": "Lesotho",
    "countryFr": "Lesotho",
    "name": "Lesotho loti",
    "nameFr": "Loti du Lesotho",
    "isoCode": "LSL"
  },
  {
    "country": "Liberia",
    "countryFr": "Libéria",
    "name": "Liberian dollar",
    "nameFr": "Dollar libérien",
    "isoCode": "LRD"
  },
  {
    "country": "Libya",
    "countryFr": "Libye",
    "name": "Libyan dinar",
    "nameFr": "Dinar libyen",
    "isoCode": "LYD"
  },
  {
    "country": "Liechtenstein",
    "countryFr": "Liechtenstein",
    "name": "Swiss franc",
    "nameFr": "Franc suisse",
    "isoCode": "CHF"
  },
  {
    "country": "Lithuania",
    "countryFr": "Lituanie",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Luxembourg",
    "countryFr": "Luxembourg",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Macau (China)",
    "countryFr": "Macau (Chine)",
    "name": "Macanese pataca",
    "nameFr": "Pataca macanaise",
    "isoCode": "MOP"
  },
  {
    "country": "Madagascar",
    "countryFr": "Madagascar",
    "name": "Malagasy ariary",
    "nameFr": "Ariary malgache",
    "isoCode": "MGA"
  },
  {
    "country": "Guinea",
    "countryFr": "Guinée",
    "name": "Guinean franc",
    "nameFr": "Franc guinéen",
    "isoCode": "GNF"
  },
  {
    "country": "Guinea-Bissau",
    "countryFr": "Guinée-Bissau",
    "name": "West African CFA franc",
    "nameFr": "Franc CFA de l'Afrique de l'Ouest",
    "isoCode": "XOF"
  },
  {
    "country": "Guyana",
    "countryFr": "Guyane",
    "name": "Guyanese dollar",
    "nameFr": "Dollar guyanien",
    "isoCode": "GYD"
  },
  {
    "country": "Haiti",
    "countryFr": "Haïti",
    "name": "Haitian gourde",
    "nameFr": "Gourde haïtienne",
    "isoCode": "HTG"
  },
  {
    "country": "Honduras",
    "countryFr": "Honduras",
    "name": "Honduran lempira",
    "nameFr": "Lempira hondurien",
    "isoCode": "HNL"
  },
  {
    "country": "Hong Kong (China)",
    "countryFr": "Hong Kong (Chine)",
    "name": "Hong Kong dollar",
    "nameFr": "Dollar de Hong Kong",
    "isoCode": "HKD"
  },
  {
    "country": "Hungary",
    "countryFr": "Hongrie",
    "name": "Hungarian forint",
    "nameFr": "Forint hongrois",
    "isoCode": "HUF"
  },
  {
    "country": "Iceland",
    "countryFr": "Islande",
    "name": "Icelandic krona",
    "nameFr": "Couronne islandaise",
    "isoCode": "ISK"
  },
  {
    "country": "India",
    "countryFr": "Inde",
    "name": "Indian rupee",
    "nameFr": "Roupie indienne",
    "isoCode": "INR"
  },
  {
    "country": "Indonesia",
    "countryFr": "Indonésie",
    "name": "Indonesian rupiah",
    "nameFr": "Roupie indonésienne",
    "isoCode": "IDR"
  },
  {
    "country": "International Monetary Fund (IMF)",
    "countryFr": "Fonds Monétaire International (FMI)",
    "name": "SDR (Special Drawing Right)",
    "nameFr": "Droits de tirage spéciaux (DTS)",
    "isoCode": "XDR"
  },
  {
    "country": "Iran",
    "countryFr": "Iran",
    "name": "Iranian rial",
    "nameFr": "Rial iranien",
    "isoCode": "IRR"
  },
  {
    "country": "Iraq",
    "countryFr": "Irak",
    "name": "Iraqi dinar",
    "nameFr": "Dinar irakien",
    "isoCode": "IQD"
  },
  {
    "country": "Ireland",
    "countryFr": "Irlande",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Isle of Man (UK)",
    "countryFr": "Île de Man (Royaume-Uni)",
    "name": "Manx pound",
    "nameFr": "Livre mannoise",
    "isoCode": "IMP"
  },
  {
    "country": "Israel",
    "countryFr": "Israël",
    "name": "Israeli new shekel",
    "nameFr": "Nouveau shekel israélien",
    "isoCode": "ILS"
  },
  {
    "country": "Italy",
    "countryFr": "Italie",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Jamaica",
    "countryFr": "Jamaïque",
    "name": "Jamaican dollar",
    "nameFr": "Dollar jamaïcain",
    "isoCode": "JMD"
  },
  {
    "country": "Japan",
    "countryFr": "Japon",
    "name": "Japanese yen",
    "nameFr": "Yen japonais",
    "isoCode": "JPY"
  },
  {
    "country": "Jersey (UK)",
    "countryFr": "Jersey (Royaume-Uni)",
    "name": "Jersey pound",
    "nameFr": "Livre de Jersey",
    "isoCode": "JEP"
  },
  {
    "country": "Jordan",
    "countryFr": "Jordanie",
    "name": "Jordanian dinar",
    "nameFr": "Dinar jordanien",
    "isoCode": "JOD"
  },
  {
    "country": "Kazakhstan",
    "countryFr": "Kazakhstan",
    "name": "Kazakhstani tenge",
    "nameFr": "Tenge kazakh",
    "isoCode": "KZT"
  },
  {
    "country": "Kenya",
    "countryFr": "Kenya",
    "name": "Kenyan shilling",
    "nameFr": "Shilling kenyan",
    "isoCode": "KES"
  },
  {
    "country": "Kiribati",
    "countryFr": "Kiribati",
    "name": "Australian dollar",
    "nameFr": "Dollar australien",
    "isoCode": "AUD"
  },
  {
    "country": "Kosovo",
    "countryFr": "Kosovo",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Kuwait",
    "countryFr": "Koweït",
    "name": "Kuwaiti dinar",
    "nameFr": "Dinar koweïtien",
    "isoCode": "KWD"
  },
  {
    "country": "Kyrgyzstan",
    "countryFr": "Kirghizistan",
    "name": "Kyrgyzstani som",
    "nameFr": "Som kirghize",
    "isoCode": "KGS"
  },
  {
    "country": "Laos",
    "countryFr": "Laos",
    "name": "Lao kip",
    "nameFr": "Kip laotien",
    "isoCode": "LAK"
  },
  {
    "country": "Latvia",
    "countryFr": "Lettonie",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Lebanon",
    "countryFr": "Liban",
    "name": "Lebanese pound",
    "nameFr": "Livre libanaise",
    "isoCode": "LBP"
  },
  {
    "country": "Lesotho",
    "countryFr": "Lesotho",
    "name": "Lesotho loti",
    "nameFr": "Loti du Lesotho",
    "isoCode": "LSL"
  },
  {
    "country": "Liberia",
    "countryFr": "Liberia",
    "name": "Liberian dollar",
    "nameFr": "Dollar libérien",
    "isoCode": "LRD"
  },
  {
    "country": "Libya",
    "countryFr": "Libye",
    "name": "Libyan dinar",
    "nameFr": "Dinar libyen",
    "isoCode": "LYD"
  },
  {
    "country": "Liechtenstein",
    "countryFr": "Liechtenstein",
    "name": "Swiss franc",
    "nameFr": "Franc suisse",
    "isoCode": "CHF"
  },
  {
    "country": "Lithuania",
    "countryFr": "Lituanie",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Luxembourg",
    "countryFr": "Luxembourg",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Macau (China)",
    "countryFr": "Macau (Chine)",
    "name": "Macanese pataca",
    "nameFr": "Pataca macanaise",
    "isoCode": "MOP"
  },
  {
    "country": "Madagascar",
    "countryFr": "Madagascar",
    "name": "Malagasy ariary",
    "nameFr": "Ariary malgache",
    "isoCode": "MGA"
  },
  {
    "country": "Malawi",
    "countryFr": "Malawi",
    "name": "Malawian kwacha",
    "nameFr": "Kwacha malawien",
    "isoCode": "MWK"
  },
  {
    "name": "Maldivian rufiyaa",
    "country": "Maldives",
    "countryFr": "Maldives",
    "isoCode": "MVR"
  },
  {
    "country": "Malaysia",
    "countryFr": "Malaisie",
    "name": "Malaysian ringgit",
    "nameFr": "Ringgit malaisien",
    "isoCode": "MYR"
  },
  {
    "country": "Malta",
    "countryFr": "Malte",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Marshall Islands",
    "countryFr": "Îles Marshall",
    "name": "United States dollar",
    "nameFr": "Dollar des États-Unis",
    "isoCode": "USD"
  },
  {
    "country": "Mauritania",
    "countryFr": "Mauritanie",
    "name": "Mauritanian ouguiya",
    "nameFr": "Ouguiya mauritanien",
    "isoCode": "MRU"
  },
  {
    "country": "Mauritius",
    "countryFr": "Maurice",
    "name": "Mauritian rupee",
    "nameFr": "Roupie mauricienne",
    "isoCode": "MUR"
  },
  {
    "country": "Mexico",
    "countryFr": "Mexique",
    "name": "Mexican peso",
    "nameFr": "Peso mexicain",
    "isoCode": "MXN"
  },
  {
    "name": "United States dollar",
    "country": "Micronesia (Federated States of)",
    "countryFr": "Micronésie",
    "isoCode": "USD"
  },
  {
    "country": "Micronesia (Federated States of)",
    "countryFr": "Micronésie (États fédérés de)",
    "name": "United States dollar",
    "nameFr": "Dollar des États-Unis",
    "isoCode": "USD"
  },
  {
    "country": "Moldova",
    "countryFr": "Moldavie",
    "name": "Moldovan leu",
    "nameFr": "Leu moldave",
    "isoCode": "MDL"
  },
  {
    "country": "Monaco",
    "countryFr": "Monaco",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Mongolia",
    "countryFr": "Mongolie",
    "name": "Mongolian tugrik",
    "nameFr": "Tugrik mongol",
    "isoCode": "MNT"
  },
  {
    "country": "Montenegro",
    "countryFr": "Monténégro",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Morocco",
    "countryFr": "Maroc",
    "name": "Moroccan dirham",
    "nameFr": "Dirham marocain",
    "isoCode": "MAD"
  },
  {
    "country": "Mozambique",
    "countryFr": "Mozambique",
    "name": "Mozambican metical",
    "nameFr": "Metical mozambicain",
    "isoCode": "MZN"
  },
  {
    "country": "Myanmar",
    "countryFr": "Myanmar",
    "name": "Burmese kyat",
    "nameFr": "Kyat birman",
    "isoCode": "MMK"
  },
  {
    "country": "Namibia",
    "countryFr": "Namibie",
    "name": "Namibian dollar",
    "nameFr": "Dollar namibien",
    "isoCode": "NAD"
  },
  {
    "country": "Nauru",
    "countryFr": "Nauru",
    "name": "Australian dollar",
    "nameFr": "Dollar australien",
    "isoCode": "AUD"
  },
  {
    "country": "Nepal",
    "countryFr": "Népal",
    "name": "Nepalese rupee",
    "nameFr": "Roupie népalaise",
    "isoCode": "NPR"
  },
  {
    "country": "Netherlands",
    "countryFr": "Pays-Bas",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "New Caledonia (France)",
    "countryFr": "Nouvelle-Calédonie (France)",
    "name": "CFP franc",
    "nameFr": "Franc CFP",
    "isoCode": "XPF"
  },
  {
    "country": "New Zealand",
    "countryFr": "Nouvelle-Zélande",
    "name": "New Zealand dollar",
    "nameFr": "Dollar néo-zélandais",
    "isoCode": "NZD"
  },
  {
    "country": "Nicaragua",
    "countryFr": "Nicaragua",
    "name": "Nicaraguan córdoba",
    "nameFr": "Córdoba nicaraguayen",
    "isoCode": "NIO"
  },
  {
    "country": "Niger",
    "countryFr": "Niger",
    "name": "West African CFA franc",
    "nameFr": "Franc CFA de l'Afrique de l'Ouest",
    "isoCode": "XOF"
  },
  {
    "country": "Nigeria",
    "countryFr": "Nigeria",
    "name": "Nigerian naira",
    "nameFr": "Naira nigérian",
    "isoCode": "NGN"
  },
  {
    "country": "Niue",
    "countryFr": "Niue",
    "name": "New Zealand dollar",
    "nameFr": "Dollar néo-zélandais",
    "isoCode": "NZD"
  },
  {
    "country": "North Macedonia",
    "countryFr": "Macédoine du Nord",
    "name": "Macedonian denar",
    "nameFr": "Dinar macédonien",
    "isoCode": "MKD"
  },
  {
    "country": "Norway",
    "countryFr": "Norvège",
    "name": "Norwegian krone",
    "nameFr": "Couronne norvégienne",
    "isoCode": "NOK"
  },
  {
    "country": "Oman",
    "countryFr": "Oman",
    "name": "Omani rial",
    "nameFr": "Rial omanais",
    "isoCode": "OMR"
  },
  {
    "country": "Pakistan",
    "countryFr": "Pakistan",
    "name": "Pakistani rupee",
    "nameFr": "Roupie pakistanaise",
    "isoCode": "PKR"
  },
  {
    "country": "Palau",
    "countryFr": "Palaos",
    "name": "United States dollar",
    "nameFr": "Dollar des États-Unis",
    "isoCode": "USD"
  },
  {
    "country": "Palestine",
    "countryFr": "Palestine",
    "name": "Israeli new shekel",
    "nameFr": "Nouveau shekel israélien",
    "isoCode": "ILS"
  },
  {
    "country": "Panama",
    "countryFr": "Panama",
    "name": "Panamanian balboa",
    "nameFr": "Balboa panaméen",
    "isoCode": "PAB"
  },
  {
    "country": "Papua New Guinea",
    "countryFr": "Papouasie-Nouvelle-Guinée",
    "name": "Papua New Guinean kina",
    "nameFr": "Kina de Papouasie-Nouvelle-Guinée",
    "isoCode": "PGK"
  },
  {
    "country": "Paraguay",
    "countryFr": "Paraguay",
    "name": "Paraguayan guarani",
    "nameFr": "Guarani paraguayen",
    "isoCode": "PYG"
  },
  {
    "country": "Peru",
    "countryFr": "Pérou",
    "name": "Peruvian sol",
    "nameFr": "Sol péruvien",
    "isoCode": "PEN"
  },
  {
    "country": "Philippines",
    "countryFr": "Philippines",
    "name": "Philippine peso",
    "nameFr": "Peso philippin",
    "isoCode": "PHP"
  },
  {
    "country": "Pitcairn Islands (UK)",
    "countryFr": "Îles Pitcairn (Royaume-Uni)",
    "name": "New Zealand dollar",
    "nameFr": "Dollar néo-zélandais",
    "isoCode": "NZD"
  },
  {
    "country": "Poland",
    "countryFr": "Pologne",
    "name": "Polish złoty",
    "nameFr": "Zloty polonais",
    "isoCode": "PLN"
  },
  {
    "country": "Portugal",
    "countryFr": "Portugal",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Qatar",
    "countryFr": "Qatar",
    "name": "Qatari riyal",
    "nameFr": "Riyal qatari",
    "isoCode": "QAR"
  },
  {
    "name": "Czech koruna",
    "country": "Czech Republic",
    "countryFr": "République tchèque",
    "isoCode": "CZK"
  },
  {
    "country": "Romania",
    "countryFr": "Roumanie",
    "name": "Romanian leu",
    "nameFr": "Leu roumain",
    "isoCode": "RON"
  },
  {
    "country": "Russia",
    "countryFr": "Russie",
    "name": "Russian ruble",
    "nameFr": "Rouble russe",
    "isoCode": "RUB"
  },
  {
    "country": "Rwanda",
    "countryFr": "Rwanda",
    "name": "Rwandan franc",
    "nameFr": "Franc rwandais",
    "isoCode": "RWF"
  },
  {
    "country": "Saint Kitts and Nevis",
    "countryFr": "Saint-Kitts-et-Nevis",
    "name": "Eastern Caribbean dollar",
    "nameFr": "Dollar des Caraïbes orientales",
    "isoCode": "XCD"
  },
  {
    "name": "East Caribbean dollar",
    "country": "Saint Kitts and Nevis",
    "countryFr": "Saint-Christophe-et-Niévès",
    "isoCode": "XCD"
  },
  {
    "country": "Saint Lucia",
    "countryFr": "Sainte-Lucie",
    "name": "Eastern Caribbean dollar",
    "nameFr": "Dollar des Caraïbes orientales",
    "isoCode": "XCD"
  },
  {
    "country": "Saint Vincent and the Grenadines",
    "countryFr": "Saint-Vincent-et-les-Grenadines",
    "name": "Eastern Caribbean dollar",
    "nameFr": "Dollar des Caraïbes orientales",
    "isoCode": "XCD"
  },
  {
    "country": "Samoa",
    "countryFr": "Samoa",
    "name": "Samoan tala",
    "nameFr": "Tala samoan",
    "isoCode": "WST"
  },
  {
    "country": "San Marino",
    "countryFr": "Saint-Marin",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Sao Tome and Principe",
    "countryFr": "Sao Tomé-et-Principe",
    "name": "São Tomé and Príncipe dobra",
    "nameFr": "Dobra de São Tomé-et-Principe",
    "isoCode": "STN"
  },
  {
    "country": "Saudi Arabia",
    "countryFr": "Arabie Saoudite",
    "name": "Saudi riyal",
    "nameFr": "Riyal saoudien",
    "isoCode": "SAR"
  },
  {
    "country": "Senegal",
    "countryFr": "Sénégal",
    "name": "West African CFA franc",
    "nameFr": "Franc CFA de l'Afrique de l'Ouest",
    "isoCode": "XOF"
  },
  {
    "country": "Serbia",
    "countryFr": "Serbie",
    "name": "Serbian dinar",
    "nameFr": "Dinar serbe",
    "isoCode": "RSD"
  },
  {
    "country": "Seychelles",
    "countryFr": "Seychelles",
    "name": "Seychellois rupee",
    "nameFr": "Roupie seychelloise",
    "isoCode": "SCR"
  },
  {
    "country": "Sierra Leone",
    "countryFr": "Sierra Leone",
    "name": "Sierra Leonean leone",
    "nameFr": "Leone sierra-léonais",
    "isoCode": "SLL"
  },
  {
    "country": "Singapore",
    "countryFr": "Singapour",
    "name": "Singapore dollar",
    "nameFr": "Dollar de Singapour",
    "isoCode": "SGD"
  },
  {
    "country": "Sint Maarten (Dutch part)",
    "countryFr": "Sint Maarten (partie néerlandaise)",
    "name": "Netherlands Antillean guilder",
    "nameFr": "Guilder antillais néerlandais",
    "isoCode": "ANG"
  },
  {
    "country": "Slovakia",
    "countryFr": "Slovaquie",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Slovenia",
    "countryFr": "Slovénie",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Solomon Islands",
    "countryFr": "Îles Salomon",
    "name": "Solomon Islands dollar",
    "nameFr": "Dollar des Îles Salomon",
    "isoCode": "SBD"
  },
  {
    "name": "West African CFA franc",
    "country": "Mali",
    "countryFr": "Mali",
    "isoCode": "XOF"
  },
  {
    "country": "Somalia",
    "countryFr": "Somalie",
    "name": "Somali shilling",
    "nameFr": "Shilling somalien",
    "isoCode": "SOS"
  },
  {
    "country": "South Africa",
    "countryFr": "Afrique du Sud",
    "name": "South African rand",
    "nameFr": "Rand sud-africain",
    "isoCode": "ZAR"
  },
  {
    "country": "South Sudan",
    "countryFr": "Soudan du Sud",
    "name": "South Sudanese pound",
    "nameFr": "Livre sud-soudanaise",
    "isoCode": "SSP"
  },
  {
    "country": "Spain",
    "countryFr": "Espagne",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Sri Lanka",
    "countryFr": "Sri Lanka",
    "name": "Sri Lankan rupee",
    "nameFr": "Roupie sri-lankaise",
    "isoCode": "LKR"
  },
  {
    "country": "Sudan",
    "countryFr": "Soudan",
    "name": "Sudanese pound",
    "nameFr": "Livre soudanaise",
    "isoCode": "SDG"
  },
  {
    "country": "Suriname",
    "countryFr": "Suriname",
    "name": "Surinamese dollar",
    "nameFr": "Dollar surinamais",
    "isoCode": "SRD"
  },
  {
    "country": "Sweden",
    "countryFr": "Suède",
    "name": "Swedish krona",
    "nameFr": "Couronne suédoise",
    "isoCode": "SEK"
  },
  {
    "country": "Switzerland",
    "countryFr": "Suisse",
    "name": "Swiss franc",
    "nameFr": "Franc suisse",
    "isoCode": "CHF"
  },
  {
    "country": "Syria",
    "countryFr": "Syrie",
    "name": "Syrian pound",
    "nameFr": "Livre syrienne",
    "isoCode": "SYP"
  },
  {
    "country": "Taiwan",
    "countryFr": "Taïwan",
    "name": "New Taiwan dollar",
    "nameFr": "Dollar de Taïwan",
    "isoCode": "TWD"
  },
  {
    "country": "Tajikistan",
    "countryFr": "Tadjikistan",
    "name": "Tajikistani somoni",
    "nameFr": "Somoni tadjik",
    "isoCode": "TJS"
  },
  {
    "country": "Tanzania",
    "countryFr": "Tanzanie",
    "name": "Tanzanian shilling",
    "nameFr": "Shilling tanzanien",
    "isoCode": "TZS"
  },
  {
    "country": "Thailand",
    "countryFr": "Thaïlande",
    "name": "Thai baht",
    "nameFr": "Baht thaïlandais",
    "isoCode": "THB"
  },
  {
    "country": "Timor-Leste",
    "countryFr": "Timor-Leste",
    "name": "United States dollar",
    "nameFr": "Dollar des États-Unis",
    "isoCode": "USD"
  },
  {
    "country": "Togo",
    "countryFr": "Togo",
    "name": "West African CFA franc",
    "nameFr": "Franc CFA de l'Afrique de l'Ouest",
    "isoCode": "XOF"
  },
  {
    "country": "Tonga",
    "countryFr": "Tonga",
    "name": "Tongan paʻanga",
    "nameFr": "Paʻanga tongien",
    "isoCode": "TOP"
  },
  {
    "country": "Trinidad and Tobago",
    "countryFr": "Trinité-et-Tobago",
    "name": "Trinidad and Tobago dollar",
    "nameFr": "Dollar de Trinité-et-Tobago",
    "isoCode": "TTD"
  },
  {
    "country": "Tunisia",
    "countryFr": "Tunisie",
    "name": "Tunisian dinar",
    "nameFr": "Dinar tunisien",
    "isoCode": "TND"
  },
  {
    "country": "Turkey",
    "countryFr": "Turquie",
    "name": "Turkish lira",
    "nameFr": "Livre turque",
    "isoCode": "TRY"
  },
  {
    "country": "Turkmenistan",
    "countryFr": "Turkménistan",
    "name": "Turkmenistani manat",
    "nameFr": "Manat turkmène",
    "isoCode": "TMT"
  },
  {
    "country": "Tuvalu",
    "countryFr": "Tuvalu",
    "name": "Australian dollar",
    "nameFr": "Dollar australien",
    "isoCode": "AUD"
  },
  {
    "country": "Uganda",
    "countryFr": "Ouganda",
    "name": "Ugandan shilling",
    "nameFr": "Shilling ougandais",
    "isoCode": "UGX"
  },
  {
    "country": "Ukraine",
    "countryFr": "Ukraine",
    "name": "Ukrainian hryvnia",
    "nameFr": "Hryvnia ukrainienne",
    "isoCode": "UAH"
  },
  {
    "country": "United Arab Emirates",
    "countryFr": "Émirats arabes unis",
    "name": "United Arab Emirates dirham",
    "nameFr": "Dirham des Émirats arabes unis",
    "isoCode": "AED"
  },
  {
    "country": "United Kingdom",
    "countryFr": "Royaume-Uni",
    "name": "Pound sterling",
    "nameFr": "Livre sterling",
    "isoCode": "GBP"
  },
  {
    "country": "United States",
    "countryFr": "États-Unis",
    "name": "United States dollar",
    "nameFr": "Dollar des États-Unis",
    "isoCode": "USD"
  },
  {
    "country": "Uruguay",
    "countryFr": "Uruguay",
    "name": "Uruguayan peso",
    "nameFr": "Peso uruguayen",
    "isoCode": "UYU"
  },
  {
    "country": "Uzbekistan",
    "countryFr": "Ouzbékistan",
    "name": "Uzbekistani som",
    "nameFr": "Som ouzbek",
    "isoCode": "UZS"
  },
  {
    "country": "Vanuatu",
    "countryFr": "Vanuatu",
    "name": "Vanuatu vatu",
    "nameFr": "Vatu vanuatuan",
    "isoCode": "VUV"
  },
  {
    "country": "Vatican City",
    "countryFr": "Vatican",
    "name": "European euro",
    "nameFr": "Euro",
    "isoCode": "EUR"
  },
  {
    "country": "Venezuela",
    "countryFr": "Venezuela",
    "name": "Venezuelan bolívar",
    "nameFr": "Bolívar vénézuélien",
    "isoCode": "VES"
  },
  {
    "country": "Vietnam",
    "countryFr": "Vietnam",
    "name": "Vietnamese đồng",
    "nameFr": "Dong vietnamien",
    "isoCode": "VND"
  },
  {
    "name": "Vietnamese đồng",
    "country": "Vietnam",
    "countryFr": "Viêt Nam",
    "isoCode": "VND"
  },
  {
    "country": "Yemen",
    "countryFr": "Yémen",
    "name": "Yemeni rial",
    "nameFr": "Rial yéménite",
    "isoCode": "YER"
  },
  {
    "country": "Zambia",
    "countryFr": "Zambie",
    "name": "Zambian kwacha",
    "nameFr": "Kwacha zambien",
    "isoCode": "ZMW"
  },
  {
    "country": "Zimbabwe",
    "countryFr": "Zimbabwe",
    "name": "United States dollar",
    "nameFr": "Dollar des États-Unis",
    "isoCode": "USD"
  }
];

currencies.sort((a, b) => a.isoCode.localeCompare(b.isoCode));

export const currenciesData = Array.from(new Map(currencies.map(item => [item['isoCode'], item])).values());

export const countriesCurrencies = currencies;
export const GA_MEASUREMENT_ID = "G-VJ0Y9D0VXY";

export const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}