import axios from 'axios'

import { url } from './constants'

const transactionUrl = url + 'api/transaction/'

const createTransaction = async (transactionObj) => {
  let response = {}
  await axios
    .post(
      transactionUrl + 'create',
      transactionObj,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message }
    })
  return response
}

const getTransactionByUserId = async (userId) => {
  let response = {}
  await axios
    .get(
      transactionUrl + 'findByUser/' + userId,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message  }
    })
  return response
}

const getTransactionById = async (id) => {
  let response = {}
  await axios
    .get(
      transactionUrl + '' + id,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message  }
    })
  return response
}

export default {
  createTransaction,
  getTransactionByUserId,
  getTransactionById,
}
