import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, styled, DialogActions, Typography, Box } from '@mui/material';
import { Share as ShareIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { baseUrl } from '../../services/constants';
import { Button } from 'primereact/button';
import RegisterModal from '../auth-modal/RegisterModal';
import OtpVerificationModal from '../auth-modal/OtpVerificationCodeModal';
import LoginModal from '../auth-modal/LoginModal';
import sharedApiRoutes from '../../services/sharedApiRoutes';

const AppShareModal = ({
  showSuccessMessage,
  showErrorMessage,
  style,
  size,
  title
}) => {
  const { t } = useTranslation();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showOtpVerificationModal, setShowOtpVerificationModal] = useState(false)
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [userLogged, setUserLogged] = useState(false)
  const [user, setUser] = useState({})
  const [visualisationToastTime, setVisualisationToastTime] = useState(3000)
  const [loader, setLoader] = useState(false)

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
  };

  const handleShareAsGuest = () => {
    // Logique pour partager en tant qu'utilisateur non connecté
    console.log("Partage en tant qu'utilisateur non connecté");
    handleCloseShareModal();
    try {
      let shareData = {
        title: t('components.share_app.share_button.discover_best_soluchange_app'),
        text: t('components.share_app.share_button.amazing_soluchange_app'),
        url: baseUrl
      }

      if (navigator.share && navigator.canShare(shareData)) {
        navigator.share(shareData)
        console.log('Partage effectué avec Succès')
      } else {
        showErrorMessage({
          title: "Error", message: (t('components.share_app.share_button.feature_available_https_dev_mode'))
        });
      }
    } catch (err) {
      console.error(`Erreur de partage : ${err}`)
    }
  };

  const handleLogin = () => {
    // Logique pour rediriger vers la page de connexion ou ouvrir une modal de connexion
    setOpenShareModal(false);
    handleCloseShareModal();
    setShowLoginModal(true);
  };

  const createSharedLink = async idUser => {
    setLoader(true)
    const response = await sharedApiRoutes.createAppLink(idUser)
    setLoader(false)

    if (Object.keys(response).includes('error')) {
      setLoader(false)
      showErrorMessage({
        title: "Error", message: (response?.error ?? t('components.share_app.share_button.error_message'))
      });
    } else {
      try {
        let shareData = {
          title: t('components.share_app.share_button.discover_best_marketplace_app'),
          text: t('components.share_app.share_button.discover_best_deals_sell_products') + ' ' + response.data + ' ' + t('components.share_app.share_button.benefit_special_discounts') + " " + baseUrl + " 🎁",
          url: baseUrl
        }
        if (navigator.share && navigator.canShare(shareData)) {
          navigator.share(shareData)
          console.log('Partage effectué avec Succès')
        } else {
          showErrorMessage({
            title: "Error", message: (t('components.share_app.share_button.feature_available_https_dev_mode'))
          });
        }
      } catch (err) {
        console.error(`Erreur de partage : ${err}`)
      }
    }
  }

  return (
    <>
      {/* <Button size="medium" className="" icon="pi pi-share-alt" severity="danger" label={t('components.share_app.shareAppTitle')} onClick={() => handleOpenShareModal()} raised /> */}
      {
        (style && style == 'link') ? <span  onClick={() => handleShareAsGuest()}  to="/" className="font-medium text-secondary">{title}</span> :
          <Button size={size} className={style} icon="pi pi-share-alt" severity="danger" label={title} onClick={() => handleShareAsGuest()} raised />
      }

      {
        openShareModal ? <Dialog open={openShareModal} onClose={handleCloseShareModal}>
          <DialogTitle className='font-semibold text-center'>{t('components.share_app.shareAppTitle')}</DialogTitle>
          <DialogContent>
            <ContentWrapper>
              <Box className='text-center'>
                <Typography className='text-center'>{t('components.share_app.shareAppMessage')}</Typography>
              </Box>
              <Box mt={2} className="flex gap-2 align-items-center">
                <Button label={t('components.share_app.shareAsGuest')} severity="secondary" text raised onClick={handleShareAsGuest} />
                <Button label={t('components.share_app.loginToShare')} severity="warning" text raised onClick={handleLogin} />
              </Box>
            </ContentWrapper>
            <IconButton
              sx={{
                position: "absolute",
                top: 3,
                right: 3,
              }}
              onClick={handleCloseShareModal}
            >
              <i className='pi pi-times'></i>
            </IconButton>
          </DialogContent>
        </Dialog> : ''
      }

      {
        showRegisterModal ? <RegisterModal
          showSuccessMessage={({ title, message }) => {
            showSuccessMessage({
              title: title, message: message
            });
          }}
          showErrorMessage={({ title, message }) => {
            showErrorMessage({
              title: title, message: message
            });
          }}
          visualisationToastTime={visualisationToastTime}
          open={showRegisterModal} onSubmit={(logUser) => {
            setShowOtpVerificationModal(true);
          }}
          openLoginModal={() => { setShowRegisterModal(false); setShowLoginModal(true); }} onClose={() => setShowRegisterModal(false)} /> : ''
      }

      {
        showOtpVerificationModal ? <OtpVerificationModal
          showSuccessMessage={({ title, message }) => {
            showSuccessMessage({
              title: title, message: message
            });
          }}
          showErrorMessage={({ title, message }) => {
            showErrorMessage({
              title: title, message: message
            });
          }}
          visualisationToastTime={visualisationToastTime}
          open={showOtpVerificationModal} onSubmit={(logUser) => {
            setUser(logUser);
            setUserLogged(true);
            setShowOtpVerificationModal(false);
            setShowLoginModal(false);
            createSharedLink(logUser?.id);
          }} onClose={() => setShowOtpVerificationModal(false)} /> : ''
      }

      {
        showLoginModal ? <LoginModal
          showOtpVerification={() => {
            setShowLoginModal(false);
            setShowRegisterModal(false);
            setShowOtpVerificationModal(true);
          }}
          showSuccessMessage={({ title, message }) => {
            showSuccessMessage({
              title: title, message: message
            });
          }}
          showErrorMessage={({ title, message }) => {
            showErrorMessage({
              title: title, message: message
            });
          }}
          visualisationToastTime={visualisationToastTime}
          onSubmit={(logUser) => {
            setShowLoginModal(false);
            setUser(logUser);
            setUserLogged(true);
            createSharedLink(logUser?.id);
          }} open={showLoginModal} openCreateModal={() => { setShowLoginModal(false); setShowRegisterModal(true); }} onClose={() => setShowLoginModal(false)} /> : ''
      }
    </>
  );
};

export default AppShareModal;


const ContentWrapper = styled(Box)(({ theme }) => ({
  '& .carousel:hover': {
    cursor: 'pointer',
    '& .carousel__back-button': {
      opacity: 1,
      left: 10
    },
    '& .carousel__next-button': {
      opacity: 1,
      right: 10
    }
  },
  '& .carousel__next-button, & .carousel__back-button': {
    opacity: 0,
    boxShadow: 'none',
    transition: 'all 0.3s',
    background: 'transparent',
    color: theme.palette.primary.main,
    ':disabled': {
      color: theme.palette.grey[500]
    },
    ':hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent'
    }
  },
  '& .carousel__back-button': {
    left: 0
  },
  '& .carousel__next-button': {
    right: 0
  }
})) // =====================================================
