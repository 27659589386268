import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import EyeToggleButton from "../EyeToggleButton";
import authApiService from "../../services/authApiService";
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/material.css'
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import {
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    styled,
    Table,
    TableContainer,
    TextField,
    Tooltip
} from '@mui/material'
import { useTranslation, Trans } from 'react-i18next';
import userApiService from '../../services/userApiService';
import Cookies from 'js-cookie';

const RegisterModal = ({ onSubmit, open, onClose, openLoginModal,
    showSuccessMessage,
    showErrorMessage,
}) => {
    const [email, setEmail] = useState(null);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [firstname, setFirstname] = useState(null);
    const [birthdate, setBirthdate] = useState(null);
    const [sexe, setSexe] = useState(null);
    const [phone, setPhone] = useState(null);
    const [passwordVisibility, setPasswordVisibility] = useState(false)
    const [loader, setLoader] = useState(false)
    const [country, setCountry] = useState({}) // The selected country.
    const { t } = useTranslation();
    const [date, setDate] = useState(new Date());

    const registerClick = useCallback(() => {
        window.gtag('event', 'create_a_new_account', {
            'event_category': 'Button',
            'event_label': 'Register Button Click',
        });
    }, []);

    const handleRegisterFormSubmit = async (event) => {
        registerClick();
        // event?.preventDefault();
        Cookies.remove('accessToken');

        // Ton code de soumission de formulaire ici
        setLoader(true);
        try {
            // Votre logique de soumission
            let response = await userApiService.registerUser(
                {
                    password,
                    email,
                    phone,
                    sexe,
                    lastname,
                    firstname,
                    "birthday": birthdate
                }
            );

            if (response?.id) {
                let result = await authApiService.register(
                    { ...response, password }
                );

                if (result?.id) {
                    localStorage.setItem('user', JSON.stringify({
                        email,
                        phone,
                    }));
                    // showSuccessMessage({
                    //     title: t("register.succeed.title"), message: t("register.succeed.message")
                    // });
                    onSubmit(result)
                    onClose();
                    // setTimeout(() => {
                    // }, "3000");
                } else {
                    showErrorMessage({
                        title: t("errors.registerFailled"), message: result.error
                    });
                }
            } else {
                showErrorMessage({
                    title: t("errors.registerFailled"), message: response.error
                });
            }
        } catch (error) {
            console.error(t("errors.loginError"), error);
        }
        setLoader(false);
    };

    const togglePasswordVisibility = useCallback(() => {
        setPasswordVisibility(visible => !visible)
    }, [])

    const signupSchema = yup.object().shape({
        lastname: yup.string().required(t("components.auth.requirement.lastnameRequired")),
        firstname: yup.string().required(t("components.auth.requirement.firstnameRequired")),
        email: yup.string().email(t("components.auth.requirement.emailRequired")),
        sexe: yup.string().required(t("components.auth.requirement.sexRequired")),
        phone: yup.string(),
        birthdate: yup
            .date()
            .required(t("components.auth.requirement.birthdateRequired"))
            .max(new Date((date.getUTCFullYear() - 18), date.getMonth(), date.getDate()), t("components.auth.requirement.dateMinValueValidation")),
        password: yup.string()
            .required(t("components.auth.requirement.passwordRequired"))
            //.required('Please Enter your password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                t("components.auth.requirement.passwordRequiredRegex")
            ),
        re_password: yup
            .string()
            .oneOf(
                [yup.ref('password'), null],
                t("components.auth.requirement.passwordNotSame")
            )
            .required(t("components.auth.requirement.reEnterPassword")),
        agreement: yup
            .bool()
            .test(
                'accord',
                'Vous devez accepter nos termes et conditions !',
                value => value === true
            )
            .required(t("components.auth.requirement.acceptTerm"))
    })

    return (
        <>
            <Dialog
                open={open}
                maxWidth={false}
                sx={{
                    zIndex: 100
                }}
            >
                <DialogContent
                    sx={{
                        maxWidth: 555,
                        width: '100%'
                    }}
                >
                    <ContentWrapper>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={signupSchema}
                            onSubmit={handleRegisterFormSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <form className="px-4 md:px-6 py-2" onSubmit={handleSubmit}>
                                    <div className='h-full w-full '>
                                        <h1 className='text-center'>
                                            {t("register.modalTitle")}
                                        </h1>
                                    </div>
                                    <div className="formgrid grid mt-2">
                                        <div className="field col md:col-12">
                                            <p className="p-0 m-0 line-height-3 text-sm">
                                                <Trans i18nKey="register.description">
                                                    Veuillez saisir votre <strong>email</strong> ou votre <strong>numéro de téléphone</strong>, selon votre préférence.
                                                </Trans>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <TextField
                                                fullWidth
                                                size='small'
                                                id='email'
                                                name='email'
                                                label={t("components.auth.email")}
                                                type='email'
                                                value={values.email}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setEmail(e.target.value)
                                                }}
                                                onBlur={handleBlur}
                                                error={!!touched.email && !!errors.email}
                                                helperText={touched.email && errors.email}
                                            />
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <PhoneInput
                                                inputStyle={{ width: '100%' }}
                                                size='small'
                                                id='phone'
                                                name='phone'
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                onFocus={(e) => {
                                                }}
                                                isValid={(value, country) => {
                                                    // if (value == country.dialCode) {
                                                    //     return "Numero de téléphone requis";
                                                    // }
                                                    if (value.match(/12345/)) {
                                                        return 'Invalid value: ' + value + ', ' + country.name;
                                                    } else if (value.match(/1234/)) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                preferredCountries={['cm']}
                                                countryCodeEditable={false}
                                                mb={1.5}
                                                variant='outlined'
                                                country={'cm'}
                                                value={values.phone}
                                                onBlur={handleBlur}
                                                error={!!touched.phone && !!errors.phone}
                                                helperText={touched.phone && errors.phone}
                                                localization={fr}
                                                onChange={phone => setPhone(phone)}
                                                enableSearch={true}
                                                enableTerritories={true}
                                                searchPlaceholder={t("global.search")}
                                                specialLabel={t("components.auth.phone")}
                                                searchNotFound={t("errors.noCorrespondance")}
                                            />

                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <TextField
                                                fullWidth
                                                size='small'
                                                id='firstname'
                                                name='firstname'
                                                label={t("components.auth.firstname")}
                                                type='text'
                                                value={values.firstname}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFirstname(e.target.value)
                                                }}
                                                onBlur={handleBlur}
                                                error={!!touched.firstname && !!errors.firstname}
                                                helperText={touched.firstname && errors.firstname}
                                            />
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <TextField
                                                fullWidth
                                                size='small'
                                                id='lastname'
                                                name='lastname'
                                                label={t("components.auth.lastname")}
                                                type='text'
                                                value={values.lastname}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setLastname(e.target.value)
                                                }}
                                                onBlur={handleBlur}
                                                error={!!touched.lastname && !!errors.lastname}
                                                helperText={touched.lastname && errors.lastname}
                                            />
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <TextField
                                                fullWidth
                                                size='small'
                                                select
                                                id='sexe'
                                                name='sexe'
                                                label={t("components.auth.sexe.label")}
                                                value={values.sexe}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setSexe(e.target.value)
                                                }}
                                                onBlur={handleBlur}
                                                error={!!touched.sexe && !!errors.sexe}
                                                helperText={touched.sexe && errors.sexe}
                                            >
                                                <MenuItem value='MASCULIN'>{t("components.auth.sexe.masculin")}</MenuItem>
                                                <MenuItem value='FEMININ'>{t("components.auth.sexe.feminin")}</MenuItem>
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <TextField
                                                fullWidth
                                                size='small'
                                                type='date'
                                                id='birthdate'
                                                name='birthdate'
                                                label={t("components.auth.birthdate")}
                                                value={values.birthdate}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setBirthdate(e.target.value)
                                                }}
                                                onBlur={handleBlur}
                                                error={!!touched.birthdate && !!errors.birthdate}
                                                helperText={touched.birthdate && errors.birthdate}
                                            />
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <TextField
                                                fullWidth
                                                size='small'
                                                name='password'
                                                label={t("components.auth.password")}
                                                variant='outlined'
                                                autoComplete='on'
                                                placeholder='*********'
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setPassword(e.target.value)
                                                }}
                                                value={values.password}
                                                type={passwordVisibility ? 'text' : 'password'}
                                                error={!!touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <EyeToggleButton
                                                            show={passwordVisibility}
                                                            click={togglePasswordVisibility}
                                                        />
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <TextField
                                                fullWidth
                                                size='small'
                                                autoComplete='on'
                                                name='re_password'
                                                variant='outlined'
                                                label={t("components.auth.confirmPassword")}
                                                placeholder='*********'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.re_password}
                                                type={passwordVisibility ? 'text' : 'password'}
                                                error={!!touched.re_password && !!errors.re_password}
                                                helperText={touched.re_password && errors.re_password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <EyeToggleButton
                                                            show={passwordVisibility}
                                                            click={togglePasswordVisibility}
                                                        />
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <FormControlLabel
                                                name='agreement'
                                                onBlur={handleBlur}
                                                error={!!touched.agreement && !!errors.agreement}
                                                helperText={touched.agreement && errors.agreement}
                                                className='agreement flex d-flex'
                                                onChange={handleChange}
                                                control={
                                                    <Checkbox
                                                        size='medium'
                                                        color='secondary'
                                                        checked={values.agreement || false}
                                                    />
                                                }
                                                label={
                                                    <p className="p-0 m-0 line-height-3 text-sm"
                                                        style={{
                                                            flexWrap: 'wrap',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start'
                                                        }}
                                                    >
                                                        {t("register.allowConditionTerm")} {" "}
                                                        <a href='/terms' target='_blank' className='text-tertiary' rel='noreferrer noopener'>
                                                            <span
                                                                ml={1}
                                                                borderBottom='1px solid'
                                                                borderColor='grey.900'
                                                            >
                                                                {t("register.conditionTerm")}
                                                            </span>
                                                        </a>
                                                    </p>
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="formgrid grid">
                                        <div className="field col md:col-12">
                                            <Button
                                                disabled={!values.agreement || loader}
                                                type="submit"
                                                variant='contained'
                                                color='error'
                                                className='w-full'
                                            >
                                                <span style={{ marginRight: '5px' }}> {t("register.title")} </span>
                                                {loader ? <i className="pi pi-spin pi-spinner" ></i> : <></>}
                                            </Button>
                                        </div>
                                        {/* <p className="p-0 -m-2 line-height-3 w-full text-right text-sm">
                                            {t("register.conditionTerm")}  <span className="text-primary cursor-pointer hover:underline" onClick={() => {
                                                openLoginModal();
                                            }}>{t("global.clickHere")}</span>
                                        </p> */}
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </ContentWrapper>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 3,
                            right: 3,
                        }}
                        onClick={onClose}
                    >
                        <i className='pi pi-times'></i>
                    </IconButton>
                </DialogContent>
            </Dialog >
        </>
    )
};

export default RegisterModal;

const initialValues = {
    lastname: '',
    firstname: '',
    email: '',
    agreement: false,
    sexe: '',
    birthdate: null,
    password: '',
    re_password: '',
    phone: '',

};



const ContentWrapper = styled(Box)(({ theme }) => ({
    '& .carousel:hover': {
        cursor: 'pointer',
        '& .carousel__back-button': {
            opacity: 1,
            left: 10
        },
        '& .carousel__next-button': {
            opacity: 1,
            right: 10
        }
    },
    '& .carousel__next-button, & .carousel__back-button': {
        opacity: 0,
        boxShadow: 'none',
        transition: 'all 0.3s',
        background: 'transparent',
        color: theme.palette.primary.main,
        ':disabled': {
            color: theme.palette.grey[500]
        },
        ':hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    },
    '& .carousel__back-button': {
        left: 0
    },
    '& .carousel__next-button': {
        right: 0
    }
})) // =====================================================
