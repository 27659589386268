import './AnnouncementCard.css'
import { TabMenu } from 'primereact/tabmenu';
import { Formik } from 'formik'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Button } from 'primereact/button';
import currencyApiService from "../../../../services/currencyApiService";
import InputLocationSearch from "../../../../components/input-location-search/InputLocationSearch";
import announcementApiService from "../../../../services/moneyAnnouncementApiService";
import currencyAnnouncementApiService from "../../../../services/currencyAnnouncementApiService";
import {
    Box, MenuItem, Select, TextField,
    FormControl,
    InputLabel,
    ListSubheader,
    InputAdornment
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { countriesCurrencies, currenciesData } from '../../../../services/constants';
import { useTranslation, Trans } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from "@mui/icons-material/Search";
import moneyAnnouncementApiService from '../../../../services/moneyAnnouncementApiService';
import * as yup from 'yup'

const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const AnnouncementCard = ({
    showValidityInput,
    hideMenuTabOffer,
    mainStyle,
    opentLoginModal,
    openPublishedModalChoice,
    showSearchResultOntheSamePage,
    searchResultOntheSamePageCallBack,
    textFieldClass,
    showSuccessMessage,
    showWarnMessage,
    showInfoMessage,
    showErrorMessage,
    showOneTab,
    showIndex,
    announceObject,
    closeDialog
}) => {
    const { t, i18n } = useTranslation();
    const [key, setKey] = useState(0); // State to force re-render
    const [cityFrom, setCityFrom] = useState(announceObject?.cityWhereHeSent ?? {});
    const [cityTo, setCityTo] = useState(announceObject?.cityWhereHeRetrieve ?? {});
    const [exchangeCity, setExchangeCity] = useState(announceObject?.exchangeCity ?? {});
    const [activeIndex, setActiveIndex] = useState(showIndex ?? 0);
    const [items, setItems] = useState([
        { label: t('components.announceModal.sendMoney.title'), },
        { label: t('components.announceModal.sendCurrency.title'), },
    ]);
    const inputRef = useRef({});
    const [shrink, setShrink] = useState(false)
    const [amountToSend, setAmountToSend] = useState(announceObject?.amountToSend ?? null);
    const [amountToReceive, setAmountToReceive] = useState(announceObject?.amountToRetrieve ?? null);
    const [rate, setRate] = useState(announceObject?.rate ?? 1);
    const [currencyFrom, setCurrencyFrom] = useState(announceObject?.currencyFrom ?? null);
    const [currencyTo, setCurrencyTo] = useState(announceObject?.currencyTo ?? null);
    const [currencies, setCurrencies] = useState(currenciesData);
    const [currenciesToChange, setCurrenciesToChange] = useState(currenciesData);
    const [currenciesToRetrieve, setCurrenciesToRetrieve] = useState(currenciesData);
    const [user, setUser] = useState({
        id: ""
    });

    const sendMoneyInitialValues = {
        announceDateLimite: '',
    };

    const today = new Date();

    const [announceDateLimite, setAnnounceDateLimite] = useState(null);

    const sendMoneySchema = yup.object().shape({
        announceDateLimite: showValidityInput ? yup
            .date()
            .required(t("components.announceModal.requierement.announceDateLimiteRequired"))
            .min(today, t("components.announceModal.requierement.announceDateLimiteMin")) :
            yup
                .date()
                .min(today, t("components.announceModal.requierement.announceDateLimiteMin")),
    })

    const sendCurrencySchema = yup.object().shape({
        announceDateLimite: showValidityInput ? yup
            .date()
            .required(t("components.announceModal.requierement.announceDateLimiteRequired"))
            .min(today, t("components.announceModal.requierement.announceDateLimiteMin")) :
            yup
                .date()
                .min(today, t("components.announceModal.requierement.announceDateLimiteMin")),
    })

    const hasCommonWord = (str1, str2) => {
        const wordsInStr1 = new Set(str1.split(/\s+/));
        const wordsInStr2 = new Set(str2.split(/\s+/));

        for (let word of wordsInStr1) {
            if (wordsInStr2.has(word)) {
                return true;
            }
        }
        return false;
    };

    // Option select menu of currencyTo
    const [selectedOptionOfCurrencyTo, setSelectedOptionOfCurrencyTo] = useState(announceObject?.currencyTo ?? " ");

    const [searchTextOfCurrencyTo, setSearchTextOfCurrencyTo] = useState(announceObject?.currencyTo ?? "");
    const displayedOptionsOfCurrencyTo = useMemo(
        () => currenciesToRetrieve.filter((option) => containsText(option?.isoCode, searchTextOfCurrencyTo)),
        [searchTextOfCurrencyTo]
    );

    // Option select menu of currencyFrom
    const [selectedOptionOfCurrencyFrom, setSelectedOptionOfCurrencyFrom] = useState(announceObject?.currencyFrom ?? " ");

    const [searchTextOfCurrencyFrom, setSearchTextOfCurrencyFrom] = useState(announceObject?.currencyFrom ?? "");
    const displayedOptionsOfCurrencyFrom = useMemo(
        () => currenciesToChange.filter((option) => containsText(option?.isoCode, searchTextOfCurrencyFrom)),
        [searchTextOfCurrencyFrom]
    );

    const saveMoneyAnnouncement = async (offer) => {
        let response = await moneyAnnouncementApiService.createAnnoucement(offer);

        if (response?.id) {
            sessionStorage.clear();
            showSuccessMessage({
                title: t('searchPage.announceSave'), message: t('searchPage.announceSaveMessage')
            });
            closeDialog();
            navigate('/profil');
        } else {
            showErrorMessage({
                title: t('searchPage.announceNotSave'), message: response.error ?? t('searchPage.problemOccurredAtThisLevel')
            });
        }
    }

    const saveCurrencyAnnouncement = async (offer) => {
        let response = await currencyAnnouncementApiService.createAnnoucement(offer);

        if (response?.id) {
            sessionStorage.clear();
            showSuccessMessage({
                title: t('searchPage.announceSave'), message: t('searchPage.announceSaveMessage')
            });
            closeDialog();
            navigate('/profil');
        } else {
            showErrorMessage({
                title: t('searchPage.announceNotSave'), message: response.error ?? t('searchPage.problemOccurredAtThisLevel')
            });
        }
    }

    // Elements
    const navigate = useNavigate();

    function getLevenshteinDistance(a, b) {
        const matrix = [];

        // Initialisation de la matrice
        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }
        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        // Remplissage de la matrice
        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1] + 1, // substitution
                        matrix[i][j - 1] + 1,     // insertion
                        matrix[i - 1][j] + 1      // suppression
                    );
                }
            }
        }

        return matrix[b.length][a.length];
    }

    function compareWords(word1, word2, threshold = 90) {
        const distance = getLevenshteinDistance(word1, word2);
        const maxLength = Math.max(word1.length, word2.length);
        const similarityPercentage = ((maxLength - distance) / maxLength) * 100;

        return similarityPercentage >= threshold;
    }

    const filterCurrenciesByName = (zone) => {
        return countriesCurrencies.filter(currency => {
            let result = (hasCommonWord(currency.country, zone) || hasCommonWord(currency.countryFr, zone));
            return result ? result : compareWords(currency.country, zone);
        });
    };

    const handleCityFromCallback = (childData) => {
        setCityFrom(childData);
        // setExchangeCity(childData);
        const matchingCurrencies = filterCurrenciesByName(childData.name);
        if (matchingCurrencies.length > 0) {
            setCurrenciesToChange(matchingCurrencies);
            setCurrencyFrom(matchingCurrencies[0].isoCode)
            setSelectedOptionOfCurrencyFrom(matchingCurrencies[0].isoCode)
            setSearchTextOfCurrencyFrom(matchingCurrencies[0].isoCode)

            if (currencyTo && currencyTo.length > 2) {
                getRate({
                    currencyTo: currencyTo, currencyFrom: matchingCurrencies[0].isoCode
                });
            }
        }
    };

    const handleExchangeCityCallback = (childData) => {
        setExchangeCity(childData);
        const matchingCurrencies = filterCurrenciesByName(childData.name);
        if (matchingCurrencies.length > 0) {
            setCurrenciesToChange(matchingCurrencies);
            setCurrencyFrom(matchingCurrencies[0].isoCode)
            setSelectedOptionOfCurrencyFrom(matchingCurrencies[0].isoCode)
            setSearchTextOfCurrencyFrom(matchingCurrencies[0].isoCode)

            if (currencyTo && currencyTo.length > 2) {
                getRate({
                    currencyTo: currencyTo, currencyFrom: matchingCurrencies[0].isoCode
                });
            }
        }
    };

    const handleCityToCallback = (childData) => {
        setCityTo(childData);
        const matchingCurrencies = filterCurrenciesByName(childData.name);
        if (matchingCurrencies.length > 0) {
            setCurrenciesToRetrieve(matchingCurrencies);
            setCurrencyTo(matchingCurrencies[0].isoCode)
            setSelectedOptionOfCurrencyTo(matchingCurrencies[0].isoCode)
            setSearchTextOfCurrencyTo(matchingCurrencies[0].isoCode)

            if (currencyFrom && currencyFrom.length > 2) {
                getRate({
                    currencyFrom: currencyFrom, currencyTo: matchingCurrencies[0].isoCode
                });
            }
        }
    };

    const redirectToResultPageForMoneyAnnouncement = ({ moneyModelRequest, response }) => {
        if (!showSearchResultOntheSamePage) {
            navigate('/search');
            if (mainStyle == "modal-card" && closeDialog) {
                closeDialog();
            }
        } else {
            searchResultOntheSamePageCallBack(moneyModelRequest, response)
        }
    }

    const searchMoneyAnnoucement = async () => {
        let userStr = JSON.parse(localStorage.getItem("user"));

        let moneyModelRequest = {
            "cityWhereHeSent": cityFrom,
            "cityWhereHeRetrieve": cityTo,
            "userId": (userStr && userStr?.id) ? userStr?.id : user?.id,
            "rate": rate,
            "amountToSend": amountToSend,
            "amountToRetrieve": amountToReceive,
            "currencyFrom": currencyFrom,
            "currencyTo": currencyTo,
            "expiredAt": announceDateLimite
        };

        if (hideMenuTabOffer) {
           await saveMoneyAnnouncement(moneyModelRequest);
        } else {
            if (
                (cityFrom && cityFrom?.name) &&
                (cityTo && cityTo?.name)
                && (rate != 0 && rate > 0)
                && (amountToSend > 0) && (amountToReceive > 0)
                && (currencyFrom && currencyFrom?.length > 2) &&
                (currencyTo && currencyTo?.length > 2)
            ) {
                let response = await announcementApiService.searchAnnoucement(moneyModelRequest);
                if (response?.error) {
                    showErrorMessage({
                        title: t("errors.errorInSearch"), message: response.error ?? t("errors.errorBeRaised")
                    });
                } else {
                    sessionStorage.setItem('moneyModel', JSON.stringify(moneyModelRequest))
                    sessionStorage.setItem('search-money-announce', JSON.stringify(response))
                    sessionStorage.setItem('search-about-money', 'true')
                    sessionStorage.setItem('search-by-zone', 'false')
    
                    sessionStorage.removeItem("currencyModel")
                    sessionStorage.removeItem("search-currency-announce")
    
                    if (mainStyle == "modal-card") {
                        if (response?.length > 0) {
                            if (openPublishedModalChoice) {
                                openPublishedModalChoice(moneyModelRequest);
                            }
                        } else {
                            if (user?.id && user?.id.length > 5) {
                                saveMoneyAnnouncement(moneyModelRequest)
                            } else {
                                if (opentLoginModal) {
                                    opentLoginModal(moneyModelRequest);
                                }
                            }
                        }
                    } else {
                        redirectToResultPageForMoneyAnnouncement(
                            {
                                moneyModelRequest: moneyModelRequest,
                                response
                            }
                        );
                    }
                }
            } else {
                showWarnMessage({
                    title: t("components.announceModal.fieldMissing"), message: t("components.announceModal.fieldRequired")
                });
            }
        }
    };

    const redirectToResultPageForCurrencyAnnouncement = (currencyModelRequest, response) => {
        if (!showSearchResultOntheSamePage) {
            navigate('/search');
            if (mainStyle == "modal-card" && closeDialog) {
                closeDialog();
            }
        } else {
            searchResultOntheSamePageCallBack(currencyModelRequest, response)
        }
    }

    const searchCurrencyAnnoucement = async () => {
        let userStr = JSON.parse(localStorage.getItem("user"));

        let currencyModelRequest =  {
            "exchangeCity": exchangeCity,
            "userId": (userStr && userStr?.id) ? userStr?.id : user?.id,
            "rate": rate,
            "amountToSend": amountToSend,
            "amountToRetrieve": amountToReceive,
            "currencyFrom": currencyFrom,
            "currencyTo": currencyTo,
            "expiredAt": announceDateLimite
        };

        if (hideMenuTabOffer) {
           await saveCurrencyAnnouncement(currencyModelRequest);
        } else {
            if (
                (exchangeCity && exchangeCity?.name)
                && (rate != 0 && rate > 0)
                && (amountToSend > 0) && (amountToReceive > 0)
                && (currencyFrom && currencyFrom?.length > 2) &&
                (currencyTo && currencyTo?.length > 2)
            ) {
                let response = await currencyAnnouncementApiService.searchAnnoucement(
                    currencyModelRequest
                );
                if (response?.error) {
                    showErrorMessage({
                        title: t("errors.errorInSearch"), message: response.error ?? t("errors.errorBeRaised")
                    });
                } else {
                    sessionStorage.setItem('currencyModel', JSON.stringify(currencyModelRequest))
                    sessionStorage.setItem('search-about-money', 'false')
                    sessionStorage.setItem('search-by-zone', 'false')
                    sessionStorage.setItem('search-currency-announce', JSON.stringify(response))
    
                    sessionStorage.removeItem("moneyModel")
                    sessionStorage.removeItem("search-money-announce")
    
    
                    if (mainStyle == "modal-card") {
                        if (response?.length > 0) {
                            // redirectToResultPageForCurrencyAnnouncement({
                            //     currencyModelRequest: currencyModelRequest,
                            //     response
                            // });
                            if (openPublishedModalChoice) {
                                openPublishedModalChoice(currencyModelRequest);
                            }
                        } else {
                            if (user?.id && user?.id.length > 5) {
                                saveCurrencyAnnouncement(currencyModelRequest)
                            } else {
                                if (opentLoginModal) {
                                    opentLoginModal(currencyModelRequest);
                                }
                            }
                        }
                    } else {
                        redirectToResultPageForCurrencyAnnouncement({
                            currencyModelRequest: currencyModelRequest,
                            response
                        });
                    }
                }
            } else {
                showWarnMessage({
                    title: t("components.announceModal.fieldMissing"), message: t("components.announceModal.fieldRequired")
                });
            }
        }
    };

    // Listen for language changes and force re-render
    useEffect(() => {
        const handleLanguageChange = () => {
            setItems([
                { label: t('components.announceModal.sendMoney.title'), },
                { label: t('components.announceModal.sendCurrency.title'), },
            ]);
            setKey(prevKey => prevKey + 1); // Increment key to force re-render
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setUser(user);
        }
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // Ton code de soumission de formulaire ici si nécessaire
        }
    };

    const getRate = async ({ currencyFrom, currencyTo }) => {
        let result = await currencyApiService.getCurrencyChangeTaxe(currencyFrom, currencyTo);
        setRate(result)
    }

    const getChangeTaxe = async ({ currencyFrom, currencyTo }) => {
        let result = await currencyApiService.getCurrencyChangeTaxe(currencyFrom, currencyTo);
        setRate(result)
        setAmountToReceive(result * amountToSend)
    }

    const getChangeTaxeForReverse = async ({ currencyFrom, currencyTo }) => {
        let result = await currencyApiService.getCurrencyChangeTaxe(currencyFrom, currencyTo);
        setRate(result)
        setAmountToSend(result * amountToReceive)
    }


    const checkUserState = () => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (!user || !user?.id) {
            showErrorMessage({
                title: t("components.auth.requirement.authRequired"), message: t("components.auth.requirement.authRequiredMessage")
            });
        }
    }

    return (
        <div className={mainStyle == "modal-card" ? "anounce-modal-card" : "anounce-card border-round-md"} style={{

        }}>
            {
                hideMenuTabOffer ? '' : (
                    <Box className={mainStyle == "modal-card" || showOneTab == true ? 'dialog-tab-menu-container' : 'tab-menu-container'}>
                        <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => {
                            setActiveIndex(e.index);
                            setCurrenciesToChange(currenciesData)
                            setCurrenciesToRetrieve(currenciesData)
                            // setCityFrom({})
                            // setCityTo({})
                            // setExchangeCity({})
                        }} />
                    </Box>
                )
            }

            <div className={mainStyle == "modal-card" ? "p-3 pt-4" : (activeIndex == 0 ? '' : 'primary-background ') + "p-3 pb-2"} >
                {
                    activeIndex === 0 ? (
                        <div className="formgrid grid">
                            <div className="field col-12 md:col-5">
                                <InputLocationSearch key={1} defaultValue={cityFrom} parentCallback={handleCityFromCallback} title={t('components.announceModal.sendMoney.sendCountry')}
                                    className={"white-background"} variant={"filled"} shrink={true} />
                            </div>
                            <div className="field col-12 md:col-2">

                            </div>
                            <div className="field col-12 md:col-5">
                                <InputLocationSearch key={2} defaultValue={cityTo} parentCallback={handleCityToCallback} title={t('components.announceModal.sendMoney.receptionCountry')}
                                    className={"white-background"} variant={"filled"} shrink={true} />
                            </div>
                            <div className="field col-12 md:col-5">
                                <TextField
                                    fullWidth
                                    size='small'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    className={textFieldClass}
                                    id='amountToSend'
                                    name='amountToSend'
                                    label={t('components.announceModal.amountToSend')}
                                    type='number'
                                    value={amountToSend}
                                    onChange={(e) => {
                                        setShrink(true);
                                        setAmountToSend(parseFloat(parseFloat(e.target.value).toFixed(2)))
                                        setAmountToReceive(parseFloat(parseFloat(e.target.value * rate).toFixed(2)))
                                    }}
                                />
                            </div>
                            <div className="field col-12 md:col-2  flex align-items-center justify-content-center">
                                <i className={mainStyle != "modal-card" ? "pi text-500 pi-arrow-right-arrow-left text-white" : "pi text-500 pi-arrow-right-arrow-left"}></i>
                            </div>
                            <div className="field col-12 md:col-5">
                                <TextField
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    className={textFieldClass}
                                    size='small'
                                    id='amountToReceive'
                                    name='amountToReceive'
                                    label={t('components.announceModal.amountToReceive')}
                                    type='number'
                                    value={amountToReceive}
                                    onChange={(e) => {
                                        setShrink(true);
                                        setAmountToReceive(parseFloat(parseFloat(e.target.value).toFixed(2)))
                                        setAmountToSend(parseFloat(parseFloat(e.target.value / rate).toFixed(2)))
                                    }}
                                />
                            </div>
                            <div className="field col-12 md:col-5">
                                <FormControl fullWidth
                                    className={textFieldClass + ' text-left'}
                                    variant="filled" style={{
                                        // backgroundColor: 'white'
                                    }}>
                                    <InputLabel id="search-select-label-for-currencyFrom">{t('components.announceModal.currencyFrom')}</InputLabel>
                                    <Select
                                        // Disables auto focus on MenuItems and allows TextField to be in focus
                                        MenuProps={{ autoFocus: false }}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        size='small'
                                        labelId="search-select-label-for-currencyFrom"
                                        id="search-select-for-currencyFrom"
                                        value={selectedOptionOfCurrencyFrom}
                                        label="Options"
                                        onChange={(e) => {
                                            setSelectedOptionOfCurrencyFrom(e.target.value)
                                            setCurrencyFrom(e.target.value);
                                            setSearchTextOfCurrencyFrom(e.target.value)
                                            getChangeTaxe(
                                                {
                                                    currencyFrom: e.target.value,
                                                    currencyTo: currencyTo
                                                }
                                            )
                                        }}
                                        onClose={() => setSearchTextOfCurrencyFrom("")}
                                        // This prevents rendering empty string in Select's value
                                        // if search text would exclude currently selected option.
                                        renderValue={() => selectedOptionOfCurrencyFrom}
                                    >
                                        {/* TextField is put into ListSubheader so that it doesn't
                                        act as a selectable item in the menu
                                        i.e. we can click the TextField without triggering any selection.*/}
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                // Autofocus on textfield
                                                autoFocus
                                                placeholder="Type to search..."
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) => setSearchTextOfCurrencyFrom(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        // Prevents autoselecting item while typing (default Select behaviour)
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {displayedOptionsOfCurrencyFrom.map((option, i) => (
                                            <MenuItem key={i} value={option.isoCode}>
                                                {option.isoCode}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="field col-12 md:col-2">

                            </div>
                            <div className="field col-12 md:col-5">
                                <FormControl fullWidth
                                    className={textFieldClass + ' text-left'}
                                    variant="filled" style={{
                                        // backgroundColor: 'white'
                                    }}>
                                    <InputLabel id="search-select-label-for-currencyTo">{t('components.announceModal.sendMoney.currencyTo')}</InputLabel>
                                    <Select
                                        // Disables auto focus on MenuItems and allows TextField to be in focus
                                        MenuProps={{ autoFocus: false }}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        size='small'
                                        labelId="search-select-label-for-currencyTo"
                                        id="search-select-for-currencyTo"
                                        value={selectedOptionOfCurrencyTo}
                                        label="Options"
                                        onChange={(e) => {
                                            setSelectedOptionOfCurrencyTo(e.target.value)
                                            setCurrencyTo(e.target.value)
                                            setSearchTextOfCurrencyTo(e.target.value)
                                            getChangeTaxeForReverse(
                                                {
                                                    currencyFrom: currencyTo,
                                                    currencyTo: currencyFrom
                                                }
                                            )
                                        }}
                                        onClose={() => setSearchTextOfCurrencyTo("")}
                                        // This prevents rendering empty string in Select's value
                                        // if search text would exclude currently selected option.
                                        renderValue={() => selectedOptionOfCurrencyTo}
                                    >
                                        {/* TextField is put into ListSubheader so that it doesn't
                                        act as a selectable item in the menu
                                        i.e. we can click the TextField without triggering any selection.*/}
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                // Autofocus on textfield
                                                autoFocus
                                                placeholder="Type to search..."
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) => setSearchTextOfCurrencyTo(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        // Prevents autoselecting item while typing (default Select behaviour)
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {displayedOptionsOfCurrencyTo.map((option, i) => (
                                            <MenuItem key={i} value={option.isoCode}>
                                                {option.isoCode}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="field col-12 md:col-4">

                            </div>
                            <div className="field col-12 md:col-4">
                                <TextField
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    focused
                                    variant="filled"
                                    className={textFieldClass}
                                    size='small'
                                    id='rate'
                                    name='rate'
                                    label={t('components.announceModal.rate')}
                                    type='number'
                                    value={rate}
                                    onChange={(e) => {
                                        setRate(e.target.value)
                                        setAmountToReceive(parseFloat(parseFloat(e.target.value * amountToSend).toFixed(2)))
                                    }}
                                />

                            </div>
                            <div className="field col-12 md:col-4">

                            </div>
                            <div className="field col-12 md:col-12">
                                {
                                    showValidityInput ? (<TextField
                                        fullWidth
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        onChange={(newValue) => setAnnounceDateLimite(newValue.target.value)}
                                        className={textFieldClass + " w-full"}
                                        id='announceDateLimite'
                                        name='announceDateLimite'
                                        label={t('components.announceModal.dateLimit')}
                                        type='date'
                                        value={announceDateLimite}
                                    />) : ''
                                }
                            </div>


                        </div>
                    ) : (
                        <div className="formgrid grid">
                            <div className="field col-12">
                                <InputLocationSearch key={3} defaultValue={exchangeCity} parentCallback={handleExchangeCityCallback} title={t('components.announceModal.sendCurrency.exchangeCountry')}
                                    className={"white-background"} variant={"filled"} shrink={true} />
                            </div>
                            <div className="field col-12 md:col-5">
                                <FormControl fullWidth
                                    className={textFieldClass + ' text-left'}
                                    variant="filled" style={{
                                        // backgroundColor: 'white'
                                    }}>
                                    <InputLabel id="search-select-label-for-currencyFrom">{t('components.announceModal.sendCurrency.currencyFrom')}</InputLabel>
                                    <Select
                                        // Disables auto focus on MenuItems and allows TextField to be in focus
                                        MenuProps={{ autoFocus: false }}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        size='small'
                                        labelId="search-select-label-for-currencyFrom"
                                        id="search-select-for-currencyFrom"
                                        value={selectedOptionOfCurrencyFrom}
                                        label="Options"
                                        onChange={(e) => {
                                            setSelectedOptionOfCurrencyFrom(e.target.value)
                                            setCurrencyFrom(e.target.value);
                                            setSearchTextOfCurrencyFrom(e.target.value)
                                            getChangeTaxe(
                                                {
                                                    currencyFrom: e.target.value,
                                                    currencyTo: currencyTo
                                                }
                                            )
                                        }}
                                        onClose={() => setSearchTextOfCurrencyFrom("")}
                                        // This prevents rendering empty string in Select's value
                                        // if search text would exclude currently selected option.
                                        renderValue={() => selectedOptionOfCurrencyFrom}
                                    >
                                        {/* TextField is put into ListSubheader so that it doesn't
                                        act as a selectable item in the menu
                                        i.e. we can click the TextField without triggering any selection.*/}
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                // Autofocus on textfield
                                                autoFocus
                                                placeholder="Type to search..."
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) => setSearchTextOfCurrencyFrom(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        // Prevents autoselecting item while typing (default Select behaviour)
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {displayedOptionsOfCurrencyFrom.map((option, i) => (
                                            <MenuItem key={i} value={option.isoCode}>
                                                {option.isoCode}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="field col-12 md:col-2">

                            </div>
                            <div className="field col-12 md:col-5">
                                <FormControl fullWidth
                                    className={textFieldClass + ' text-left'}
                                    variant="filled" style={{
                                        // backgroundColor: 'white'
                                    }}>
                                    <InputLabel id="search-select-label-for-currencyTo">{t('components.announceModal.currencyTo')}</InputLabel>
                                    <Select
                                        // Disables auto focus on MenuItems and allows TextField to be in focus
                                        MenuProps={{ autoFocus: false }}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        size='small'
                                        labelId="search-select-label-for-currencyTo"
                                        id="search-select-for-currencyTo"
                                        value={selectedOptionOfCurrencyTo}
                                        label="Options"
                                        onChange={(e) => {
                                            setSelectedOptionOfCurrencyTo(e.target.value)
                                            setCurrencyTo(e.target.value)
                                            setSearchTextOfCurrencyTo(e.target.value)
                                            getChangeTaxeForReverse(
                                                {
                                                    currencyFrom: currencyTo,
                                                    currencyTo: currencyFrom
                                                }
                                            )
                                        }}
                                        onClose={() => setSearchTextOfCurrencyTo("")}
                                        // This prevents rendering empty string in Select's value
                                        // if search text would exclude currently selected option.
                                        renderValue={() => selectedOptionOfCurrencyTo}
                                    >
                                        {/* TextField is put into ListSubheader so that it doesn't
                                        act as a selectable item in the menu
                                        i.e. we can click the TextField without triggering any selection.*/}
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                // Autofocus on textfield
                                                autoFocus
                                                placeholder="Type to search..."
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) => setSearchTextOfCurrencyTo(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        // Prevents autoselecting item while typing (default Select behaviour)
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {displayedOptionsOfCurrencyTo.map((option, i) => (
                                            <MenuItem key={i} value={option.isoCode}>
                                                {option.isoCode}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="field col-12 md:col-5">
                                <TextField
                                    fullWidth
                                    size='small'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    className={textFieldClass}
                                    id='amountToSend'
                                    name='amountToSend'
                                    label={t('components.announceModal.sendCurrency.amountToChange')}
                                    type='number'
                                    value={amountToSend}
                                    onChange={(e) => {
                                        setShrink(true);
                                        setAmountToSend(parseFloat(parseFloat(e.target.value).toFixed(2)))
                                        setAmountToReceive(parseFloat(parseFloat(e.target.value * rate).toFixed(2)))
                                    }}
                                />
                            </div>
                            <div className="field col-12 md:col-2  flex align-items-center justify-content-center">
                                <i className={mainStyle != "modal-card" ? "pi text-500 pi-arrow-right-arrow-left text-white" : "pi text-500 pi-arrow-right-arrow-left"}></i>
                            </div>
                            <div className="field col-12 md:col-5">
                                <TextField
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    className={textFieldClass}
                                    size='small'
                                    id='amountToReceive'
                                    name='amountToReceive'
                                    label={t('components.announceModal.amountToReceive')}
                                    type='number'
                                    value={amountToReceive}
                                    onChange={(e) => {
                                        setShrink(true);
                                        setAmountToReceive(parseFloat(parseFloat(e.target.value).toFixed(2)))
                                        setAmountToSend(parseFloat(parseFloat((e.target.value / rate).toFixed(2))))
                                    }}
                                />
                            </div>
                            <div className="field col-12 md:col-4">

                            </div>
                            <div className="field col-12 md:col-4">
                                <TextField
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    focused
                                    variant="filled"
                                    className={textFieldClass}
                                    size='small'
                                    id='rate'
                                    name='rate'
                                    label={t('components.announceModal.rate')}
                                    type='number'
                                    value={rate}
                                    onChange={(e) => {
                                        setRate(parseFloat(e.target.value))
                                        setAmountToReceive(parseFloat(parseFloat(e.target.value * amountToSend).toFixed(2)))
                                    }}
                                />

                            </div>
                            <div className="field col-12 md:col-4">

                            </div>
                            <div className="field col-12 md:col-12">
                                {
                                    showValidityInput ? (<TextField
                                        fullWidth
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        onChange={(newValue) => setAnnounceDateLimite(newValue.target.value)}
                                        className={textFieldClass + " w-full"}
                                        id='announceDateLimite'
                                        name='announceDateLimite'
                                        label={t('components.announceModal.dateLimit')}
                                        type='date'
                                        value={announceDateLimite}
                                    />) : ''
                                }
                            </div>

                        </div>
                    )
                }

                <div className="formgrid grid">
                    <div className="field col md:col-12 text-center">
                        <Button size="small" onClick={() => {
                            activeIndex == 0 ? searchMoneyAnnoucement() : searchCurrencyAnnoucement()
                        }} label={mainStyle == "modal-card" || hideMenuTabOffer ? t('components.announceModal.addAnAnnounceBtn') : t('global.search')} className="border-white mt-3 bg-white text-primary " raised />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnnouncementCard;
