import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Toast } from 'primereact/toast';
import {
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    styled,
    Collapse,
    Table,
    TableContainer,
    TextField,
    Tooltip
} from '@mui/material'
import { useTranslation, Trans } from 'react-i18next';
import moneyAnnouncementApiService from '../../../../services/moneyAnnouncementApiService';
import currencyAnnouncementApiService from '../../../../services/currencyAnnouncementApiService';
import { useNavigate } from 'react-router-dom';

const PublishedOfferChoiceModal = ({ onSubmit, open, onCloseOtherModal, onClose, offer, 
    openLoginModal, showSuccessMessage, showErrorMessage }) => {
    const [loader, setLoader] = useState(false)
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [user, setUser] = useState({
        id: ""
    });

    useEffect(() => {
        showSuccessMessage({
            title: t('searchPage.announceSave'), message: t('searchPage.announceSaveMessage')
        });

        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setUser(user);
        }
    }, []);

    const saveMoneyAnnouncement = async (announce) => {
        let response = await moneyAnnouncementApiService.createAnnoucement(announce);

        if (response?.id) {
            sessionStorage.clear();
            if (showSuccessMessage) {
                showSuccessMessage({
                    title: t('searchPage.announceSave'), message: t('searchPage.announceSaveMessage')
                });
            }
            onClose();
            navigate('/profil');
        } else {
            if (showErrorMessage) {
                setDisplaySuccessMessage(false);
                setDisplayErrorMessage(true);
                setErrorMessage(response.error ?? t('searchPage.problemOccurredAtThisLevel'))
                showErrorMessage({
                    title: t('searchPage.announceNotSave'), message: response.error ?? t('searchPage.problemOccurredAtThisLevel')
                });
            }
        }
    }

    const saveCurrencyAnnouncement = async (announce) => {
        let response = await currencyAnnouncementApiService.createAnnoucement(announce);

        if (response?.id) {
            sessionStorage.clear();
            setDisplayErrorMessage(false);
            setDisplaySuccessMessage(true);
            showSuccessMessage({
                title: t('searchPage.announceSave'), message: t('searchPage.announceSaveMessage')
            });
            onClose();
            navigate('/profil');
        } else {
            setDisplaySuccessMessage(false);
            setDisplayErrorMessage(true);
            showErrorMessage({
                title: t('searchPage.announceNotSave'), message: response.error ?? t('searchPage.problemOccurredAtThisLevel')
            });
        }
    }


    return (
        <>
            <Dialog
                open={open}
                maxWidth={false}
                sx={{
                    zIndex: 100
                }}
            >
                <DialogContent
                    sx={{
                        maxWidth: 555,
                        width: '100%'
                    }}
                >
                    <ContentWrapper>
                        <div className='h-full w-full '>
                            <h1 className='text-center mt-0 mb-3'>
                                {t("components.publishChoiceModal.title")}
                            </h1>
                            <p className='text-center line-height-3 mt-0 mb-3'>
                                <Trans i18nKey="components.publishChoiceModal.message">
                                    Nous avons trouvé des  correspondant à votre besoin.<br /> Souhaitez-vous afficher les offres trouvées ou publiez toute de même le vôtre ?
                                </Trans>
                            </p>
                            <Collapse in={displayErrorMessage || displaySuccessMessage}>
                                <p className='text-center line-height-3 mt-0 mb-3'>
                                    {displaySuccessMessage ? t("searchPage.announceSaveMessage") : errorMessage}
                                </p>
                            </Collapse>
                        </div>
                        <div className='grid'>
                            <div className="col-12 md:col-6">
                                <Button
                                    type="button"
                                    variant='contained'
                                    color='error'
                                    className='w-full'
                                    onClick={() => {
                                        navigate('/search');
                                        onClose();
                                        if (onCloseOtherModal) {
                                            onCloseOtherModal()
                                        }
                                    }}
                                    disabled={loader}
                                >
                                    <span style={{ marginRight: '5px' }}>{t("components.publishChoiceModal.buttons-group.showOfferBtn")}</span>
                                    {loader ? <i className="pi pi-spin pi-spinner" ></i> : <></>}
                                </Button>
                            </div>
                            <div className="col-12 md:col-6">
                                <Button
                                    type="button"
                                    variant='contained'
                                    color='info'
                                    className='w-full'
                                    disabled={loader}
                                    onClick={() => {
                                        if (offer) {
                                            if (user?.id && user?.id.length > 5) {
                                                offer.userId = user?.id;
                                                if (Object.keys(offer).includes("cityWhereHeRetrieve")) {
                                                    saveMoneyAnnouncement(offer);
                                                } else {
                                                    saveCurrencyAnnouncement(offer);
                                                }
                                            } else {
                                                if (openLoginModal) {
                                                    openLoginModal(offer)
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <span style={{ marginRight: '5px' }}>{t("components.publishChoiceModal.buttons-group.publishedBtn")}</span>
                                    {loader ? <i className="pi pi-spin pi-spinner" ></i> : <></>}
                                </Button>
                            </div>
                        </div>

                    </ContentWrapper>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 3,
                            right: 3,
                        }}
                        onClick={onClose}
                    >
                        <i className='pi  pi-times'></i>
                    </IconButton>
                </DialogContent>
            </Dialog >
        </>
    )
};

export default PublishedOfferChoiceModal;

const ContentWrapper = styled(Box)(({ theme }) => ({
    '& .carousel:hover': {
        cursor: 'pointer',
        '& .carousel__back-button': {
            opacity: 1,
            left: 10
        },
        '& .carousel__next-button': {
            opacity: 1,
            right: 10
        }
    },
    '& .carousel__next-button, & .carousel__back-button': {
        opacity: 0,
        boxShadow: 'none',
        transition: 'all 0.3s',
        background: 'transparent',
        color: theme.palette.primary.main,
        ':disabled': {
            color: theme.palette.grey[500]
        },
        ':hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    },
    '& .carousel__back-button': {
        left: 0
    },
    '& .carousel__next-button': {
        right: 0
    }
})) // =====================================================
