// src/components/Footer.js
import React from 'react';

const EyeToggleButton = ({ passwordVisibility, click }) => {

    return (
        <div onClick={click}>
            {
                passwordVisibility ? (<i className='pi pi-eye-slash'></i>) : (<i className='pi pi-eye'></i>)
            }
        </div>
    )
}

export default EyeToggleButton;
