import axios from 'axios'

import { url } from './constants'

const userUrl = url + 'api/user/'

const getUserById = async (userId) => {
  let response = {}
  await axios
    .get(
      userUrl + '' + userId,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })
  return response
}

const getUserByEmail = async (email) => {
  let response = {}
  await axios
    .get(
      userUrl + 'findByEmailOrPhone/' + email,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })
  return response
}

const getPercentOfFillingAccountOfUser = async (userId) => {
  let response = {}
  await axios
    .get(
      userUrl + 'percentOfProfil/' + userId,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })
  return response
}

const updateUser = async (userId, user) => {
  let response = {}
  await axios
    .put(
      userUrl + '' + userId,
      user,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })
  return response
}

const registerUser = async (user) => {
  let response = {}
  await axios
    .post(
      userUrl + 'create',
      user,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })
  return response
}

const checkUserCode = async (userCode) => {
  let response = {}
  await axios
    .post(
      userUrl + 'check-code',
      userCode,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })
  return response
}

export default {
  getUserById,
  getUserByEmail,
  updateUser,
  registerUser,
  checkUserCode,
  getPercentOfFillingAccountOfUser
}
