import React, { useRef, useEffect, useState } from 'react'
import commentApiService from '../../../services/commentApiService';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

const UserComment = () => {
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({})
    const [rating, setRating] = useState(0)
    const [showStat, setShowStat] = useState(false)
    const [receivedComments, setReceivedComments] = useState([]);
    const [sendComments, setSendComments] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            getReceivedCommentByUserId(user?.id);
            getSentCommentByUserId(user?.id);
        }
        ReactGA.send({
            hitType: "pageview",
            page: "profil_comment_page"
        });
    }, [])

    const getReceivedCommentByUserId = async (userId) => {
        setLoading(true);
        let response = await commentApiService.getReceivedCommentByUserId(userId);
        setReceivedComments(response);
        setLoading(false);
    }

    const getSentCommentByUserId = async (userId) => {
        setLoading(true);
        let response = await commentApiService.getSendCommentByUserId(userId);
        setSendComments(response);
        setLoading(false);
    }

    return (
        <div className=''>
            <h3 className='text mt-0 pt-0'>{t('userCommentPage.title')} <br />
                <div className='text-xl font-medium mt-3'>{t('userCommentPage.sendCommentTile')}</div>
            </h3>
            <div>
                {
                    sendComments.length > 0 ? (
                        <div>

                        </div>
                    ) : (
                        <p>{t('userCommentPage.noSendComment')}</p>
                    )
                }
            </div>

            
            <h3 className='text mt-4 pt-0'>
                <div className='text-xl font-medium mt-3'>{t('userCommentPage.receivedCommentTile')}</div>
            </h3>
            <div>
                {
                    receivedComments.length > 0 ? (
                        <div>
                            
                        </div>
                    ) : (
                        <p>{t('userCommentPage.noReceivedComment')}</p>
                    )
                }
            </div>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-VJ0Y9D0VXY"></script>
            <script src='https://soluchange.com/assets/scripts/google_analytic.js'> </script>
        </div>
    );
};

export default UserComment;
