// src/components/Footer.js
import { Box } from '@mui/material';
import React from 'react';

const NotFound = () => {
  return (
    <Box></Box>
  );
}

export default NotFound;
