import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import { Toast } from 'primereact/toast';
import transactionApiService from '../../../services/transactionApiService';
import MessageIcon from '@mui/icons-material/Message';
import { format } from 'date-fns';
import { t } from 'i18next';
import useWindowSize from '../../../components/layout/WindowResize';
import { Paginator } from 'primereact/paginator';
import { Card } from 'primereact/card';

const ResultSearchAnnouncementTable = ({ announcements, writeAboutAnnounce, showSaveAnnouncementBtn, saveAnnouncement, showCurrencyAnnouncement }) => {
    const [width, height] = useWindowSize();
    const toast = useRef(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        amountToSend: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        amountToRetrieve: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        rate: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [logUser, setLogUser] = useState({
        id: ""
    });
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const cityFromBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.cityWhereHeSent.name}</span>
            </div>
        );
    };

    const cityToBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.cityWhereHeRetrieve.name}</span>
            </div>
        );
    };

    const exchangeCityTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.exchangeCity.name}</span>
            </div>
        );
    };

    const emptyMessageTemplate = () => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{t('searchPage.emptyAnnounce')}</span>
                {
                    showSaveAnnouncementBtn ? (
                        // <Button link icon="pi pi-plus" label='Enregistrer votre annonce' className='text-primary ccursor-pointer' onClick={() => { saveAnnouncement() }} />
                        <Button raised icon="pi pi-plus" label={t('searchPage.postAnAnnounce')} className='bg-primary border-color-primary  cursor-pointer' onClick={() => { saveAnnouncement() }} />
                    ) : ''
                }
            </div>
        );
    };

    const amountToSendBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.amountToSend + " " + rowData.currencyFrom}</span>
            </div>
        );
    };

    const amountToRetrieveBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.amountToRetrieve + " " + rowData.currencyTo}</span>
            </div>
        );
    };

    const OwnerBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData?.owner?.lastname} {rowData?.owner?.firstname}</span>
            </div>
        );
    };

    const expiredBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData?.announceAt ? format(new Date(rowData.announceAt), 'dd MMMM yyyy', 'fr') : ''}</span>
            </div>
        );
    };

    const contactClick = useCallback(() => {
        window.gtag('event','contact_partner_for_announce', {
            'event_category': 'Button',
            'event_label': 'Contact Button Click'
        });
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionsButtons = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <Button className="bg-primary bg-primary-hover border-color-primary" raised label="" onClick={() => { contactClick(); writeAboutAnnounce(rowData?.id, rowData?.userOwnerId) }}>
                    <MessageIcon />
                </Button>
            </div>
        );
    };

    const showSuccessMessage = ({ title, message }) => {
        toast.current.show({ severity: 'success', summary: title, detail: message, life: 3000 });
    }

    const showErrorMessage = ({ title, message }) => {
        toast.current.show({ severity: 'error', summary: title, detail: message, life: 3000 });
    }

    const accept = async (announceId) => {
        let response = await transactionApiService.createTransaction({
            announceId: announceId,
            userId: logUser.id,
        });

        if (response?.error) {
            showErrorMessage({
                message: response?.error,
                title: t("errors.validation")
            })
        } else {
            showSuccessMessage({
                message: t('messagePage.realyTakeInAccount'),
                title: t('searchPage.announceValidation')
            })
        }
    };

    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const confirmAnnounce = (event, announceId) => {
        confirmPopup({
            target: event.currentTarget,
            message: t('messagePage.areYouSureToValidate'),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: accept(announceId),
            reject
        });
    };


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setLogUser(user);
        }
    }, [])

    const footer = (announcement) => (
        <>
            <Button className="bg-primary bg-primary-hover border-color-primary" raised label="Contacter" onClick={() => { contactClick(); writeAboutAnnounce(announcement?.id, announcement?.userOwnerId) }}>
            </Button>
        </>
    );

    // Déterminer les annonces à afficher en fonction de la pagination
    const paginatedAnnouncements = announcements && announcements?.length > 0 ? announcements.slice(first, first + rows) : [];


    return (
        <div className={width <= 587 ? "" : "card shadow-1"}>
            <ConfirmPopup />
            <Toast ref={toast} />
            {width <= 587 ? (
                <Box>
                    {
                        paginatedAnnouncements.length <= 0 ?
                            (<>
                                <p className='mb-2'>{t('searchPage.emptyAnnounce')}</p>

                                <Button raised icon="pi pi-plus" label={t('searchPage.postAnAnnounce')} className='bg-primary border-color-primary cursor-pointer mb-3' onClick={() => { saveAnnouncement() }} />
                            </>)
                            : ''
                    }
                    {(
                        showCurrencyAnnouncement ? paginatedAnnouncements.map((announcement, index) => (
                            <div key={index} className="card flex justify-content-center mb-3">
                                <Card title={announcement?.exchangeCity?.name} subTitle={announcement?.amountToSend + "" + announcement?.currencyFrom + " -> " + announcement?.amountToRetrieve + "" + announcement?.currencyTo + " (" + t('components.announceModal.rate') + " : " + announcement?.rate + ")"} footer={footer(announcement)} header={""} className="w-full">
                                    <p className="m-0 mb-2">
                                        {t("messagePage.publicationDate")} : <strong>{announcement?.announceAt ? format(new Date(announcement.announceAt), 'dd MMMM yyyy', 'fr') : ''}</strong>
                                    </p>
                                    <p className="m-0">
                                        {t("searchPage.publishedBy")} : <strong>{announcement?.owner?.lastname} {announcement?.owner?.firstname}</strong>
                                    </p>
                                </Card>
                            </div>
                        ))
                            : paginatedAnnouncements.map((announcement, index) => (
                                <div key={index} className="card mb-3 flex justify-content-center">
                                    <Card title={announcement?.cityWhereHeSent?.name + " -> " + announcement?.cityWhereHeRetrieve?.name} subTitle={announcement?.amountToSend + "" + announcement?.currencyFrom + " -> " + announcement?.amountToRetrieve + "" + announcement?.currencyTo + " (" + t('components.announceModal.rate') + " : " + announcement?.rate + ")"} footer={footer(announcement)} header={""} className="w-full">
                                        <p className="m-0  mb-2">
                                            {t("messagePage.publicationDate")} : <strong>{announcement?.announceAt ? format(new Date(announcement.announceAt), 'dd MMMM yyyy', 'fr') : ''}</strong>
                                        </p>
                                        <p className="m-0">
                                            {t("searchPage.publishedBy")} : <strong>{announcement?.owner?.lastname} {announcement?.owner?.firstname}</strong>
                                        </p>
                                    </Card>
                                </div>
                            ))
                    )}
                    <Paginator first={first} rows={rows} totalRecords={announcements.length} rowsPerPageOptions={[5, 10, 20, 30]} onPageChange={onPageChange} />

                </Box>
            ) :
                showCurrencyAnnouncement ? (
                    <DataTable showGridlines value={announcements} sortMode="multiple" tableStyle={{ minWidth: '50rem' }} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                        dataKey="id"
                        globalFilterFields={['amountToSend', 'amountToRetrieve', 'rate',]} emptyMessage={emptyMessageTemplate}
                    >
                        <Column field="amountToSend" header={t('components.announceModal.amountToSend')} style={{ width: '25%', minWidth: '10rem' }} body={amountToSendBodyTemplate} sortable></Column>
                        <Column field="amountToRetrieve" header={t('components.announceModal.amountToReceive')} style={{ width: '25%', minWidth: '10rem' }} body={amountToRetrieveBodyTemplate} sortable></Column>
                        <Column field="rate" header={t('components.announceModal.rate')} sortable style={{ width: '25%', minWidth: '10rem' }} ></Column>
                        <Column field="exchangeCity" header={t('components.announceModal.sendCurrency.exchangeCity')} sortable body={exchangeCityTemplate} style={{ width: '25%', minWidth: '10rem' }} ></Column>
                        <Column field="announceAt" header={t('searchPage.publicationDate')} sortable body={expiredBodyTemplate} style={{ width: '25%', minWidth: '13rem' }} ></Column>
                        <Column field="owner" header={t('searchPage.announceOwner')} sortable body={OwnerBodyTemplate} style={{ width: '25%', minWidth: '13rem' }} ></Column>
                        <Column headerStyle={{ width: '10%', minWidth: '6rem' }} bodyStyle={{ textAlign: 'center' }} body={actionsButtons}></Column>
                    </DataTable>
                ) : (
                    <DataTable showGridlines value={announcements} tableStyle={{ minWidth: '50rem' }} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                        dataKey="id"
                        globalFilterFields={['amountToSend', 'amountToRetrieve', 'rate']} emptyMessage={emptyMessageTemplate}
                    >
                        <Column field="amountToRetrieve" header={t('components.announceModal.amountToSend')} sortable style={{ width: '25%', minWidth: '10rem' }} body={amountToRetrieveBodyTemplate}></Column>
                        <Column field="amountToSend" header={t('components.announceModal.amountToReceive')} sortable style={{ width: '25%', minWidth: '10rem' }} body={amountToSendBodyTemplate}></Column>
                        <Column field="rate" header={t('components.announceModal.rate')} sortable style={{ width: '25%', minWidth: '10rem' }} ></Column>
                        <Column field="cityWhereHeRetrieve" header={t('components.announceModal.sendMoney.receptionCity')} style={{ width: '25%', minWidth: '10rem' }} sortable body={cityToBodyTemplate} ></Column>
                        <Column field="cityWhereHeSent" header={t('components.announceModal.sendMoney.sendCity')} style={{ width: '25%', minWidth: '10rem' }} sortable body={cityFromBodyTemplate} ></Column>
                        <Column field="announceAt" header={t('searchPage.publicationDate')} sortable body={expiredBodyTemplate} style={{ width: '25%', minWidth: '13rem' }} ></Column>
                        <Column field="owner" header={t('searchPage.announceOwner')} sortable body={OwnerBodyTemplate} style={{ width: '25%', minWidth: '13rem' }} ></Column>
                        <Column headerStyle={{ width: '10%', minWidth: '6rem' }} bodyStyle={{ textAlign: 'center' }} body={actionsButtons}></Column>
                    </DataTable>
                )
            }

        </div>
    );
}

export default ResultSearchAnnouncementTable;
