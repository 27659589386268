import { Stomp } from '@stomp/stompjs';
import React, { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { url } from '../../services/constants';
import { useTranslation, Trans } from 'react-i18next';

const WebSocketComponent = ({
    showSuccessMessage,
    showErrorMessage,
    showInfoMessage
}) => {
    const [messages, setMessages] = useState([]);
    const [stompClient, setStompClient] = useState(null);
    const [privateStompClient, setPrivateStompClient] = useState(null);
    const [text, setText] = useState('');
    const [privateText, setPrivateText] = useState('');
    const [to, setTo] = useState('');
    const { t } = useTranslation();
    const [user, setUser] = useState({
        id: ""
    })

    useEffect(() => {
        const socket = new SockJS(url + 'api/message/ws');
        const stompClient = Stomp.over(socket);
        stompClient.connect({}, (frame) => {
            console.log(frame);
            stompClient.subscribe('/api/message/all/messages', (result) => {
                const message = JSON.parse(result.body);
                show(message);
            });
        });

        const privateSocket = new SockJS(url + 'api/message/ws');
        const privateStompClient = Stomp.over(privateSocket);
        privateStompClient.connect({}, (frame) => {
            console.log('frame: ', frame);
            privateStompClient.subscribe('api/message/specific', (result) => {
                console.log('private result: ', result.body);
                const message = JSON.parse(result.body);
                show(message);
            });
        });
        console.log('user: ', window.location.pathname);

        setStompClient(stompClient);
        setPrivateStompClient(privateStompClient);

        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setUser(user);
        }

        return () => {
            if (stompClient) stompClient.disconnect();
            if (privateStompClient) privateStompClient.disconnect();
        };
    }, []);

    const sendMessage = () => {
        if (stompClient && text) {
            stompClient.send('/api/message/app/application', {}, JSON.stringify({ 'message': text }));
            setText('');
        }
    };

    const sendPrivateMessage = () => {
        if (privateStompClient && privateText && to) {
            privateStompClient.send('/api/message/app/private', {}, JSON.stringify({ 'message': privateText, 'sendTo': to }));
            setPrivateText('');
            setTo('');
        }
    };

    const show = (message) => {
        console.log('messgae: ', message);
        console.log('user: ', user);
        console.log('message?.sendTo: ', message?.sendTo);

        if (user?.id.length > 0 && message?.sendTo == user?.id) {
            showInfoMessage({
                title: t('components.websocket.new_message') + t('global.of') + (message?.sendBy ?? ''), message: message.message
            });
            setMessages(prevMessages => [...prevMessages, message]);

            if (window.location.pathname.indexOf("/profil/announce/") > -1 ||
                window.location.pathname.indexOf("/message-detail/announce/") > -1) {
                window.setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }
        }
    };

    return (
        <></>
    );
};

export default WebSocketComponent;
