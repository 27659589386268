import React, { useRef, useEffect, useState } from 'react'
import announcementApiService from '../../../services/announcementApiService';
import messageApiService from '../../../services/messageApiService';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

const MessageAnnouncement = () => {
    document.title = "Profil Message Announce Page";

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const toast = useRef(null);
    const [user, setUser] = useState({})
    const [announcement, setAnnouncement] = useState({})
    const { announceId } = useParams();
    const [messages, setMessages] = useState([]);

    const getAnnounceById = async (announceId) => {
        let response = await announcementApiService.getAnnounceById(announceId);
        if (response?.error) {
            showErrorMessage({
                title: t('errors.problemOccurredForAnnounce'), message: response?.error
            });
        } else {
            setAnnouncement(response);
        }
    }

    useEffect(() => {
        getAnnounceById(announceId)
    }, [announceId])


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setUser(user);
            getMessageByAnnounceIdAndOwner(user?.id);
        }

        ReactGA.send({
            hitType: "pageview",
            page: "profil_message_announce_page"
        });
    }, [])


    const getMessageByAnnounceIdAndOwner = async (userId) => {
        setLoading(true);
        let response = await messageApiService.getMessageByAnnounceIdAndOwnerOfAnnounce(announceId, userId);
        setMessages(response);
        setLoading(false);
    }


    const showErrorMessage = ({ title, message }) => {
        toast.current.show({ severity: 'error', summary: title, detail: message, life: 3000 });
    }


    return (
        <div className=''>
            <Toast ref={toast} />

            <h3 className='text mt-0 pt-0'>{t('annoucementPage.title')}<br />
                <div className='text-xl font-medium mt-3'>
                    {
                        announcement?.cityWhereHeSent?.name ? (
                            <div className='line-height-3 text-xl font-medium mt-3'>{t('messagePage.cityWhereHeSent', {
                                cityWhereHeSentName: announcement?.cityWhereHeSent?.name,
                                cityWhereHeRetrieveName: announcement?.cityWhereHeRetrieve?.name
                            })} ({announcement?.amountToSend + " " + announcement?.currencyFrom + "    ->    " + announcement?.amountToRetrieve + " " + announcement?.currencyTo})</div>
                        ) : (
                            <div className='line-height-3 text-xl font-medium mt-3'>{t("messagePage.exchangeCity", {
                                name: announcement?.exchangeCity?.name
                            })}</div>
                        )
                    }
                </div>
            </h3>


            <div className="messages">
                {messages.map((message) => (
                    <>
                        <Link to={"/message-detail/announce/" + message?.announcement?.id + "/sender/" + message?.user?.id + "/owner/" + message?.ownerOfAnnouncement?.id} className='cursor-pointer hover:bg-blue-50 py-2 no-underline text-gray-900'>
                            <div className="message  flex align-items-center">
                                <div className='mr-6'>
                                    <img src={message?.user?.avatar ?? '/assets/images/001-man.svg'} className='w-4rem' alt="Aurore Finnys" />
                                </div>
                                <div className='flex-grow-1'>
                                    {
                                        message?.announcement?.cityWhereHeSent?.name ? (
                                            <h3 className='line-height-3 mt-0 mb-2'>
                                                {t('messagePage.cityWhereHeSent', {
                                                    cityWhereHeSentName: message?.announcement?.cityWhereHeSent?.name,
                                                    cityWhereHeRetrieveName: message?.announcement?.cityWhereHeRetrieve?.name
                                                })} ({message?.announcement?.amountToSend + " " + message?.announcement?.currencyTo + "    ->    " + message?.announcement?.amountToRetrieve + " " + message?.announcement?.currencyFrom})</h3>
                                        ) : (
                                            <h3 className='line-height-3 mt-0 mb-2'>
                                                {t("messagePage.exchangeCity", {
                                                    name: message?.announcement?.exchangeCity?.name
                                                })}
                                            </h3>
                                        )
                                    }
                                    <p className='m-0 line-height-3'>{message.message}</p>
                                    <div className='text-sm mt-2'>
                                        <span>{message?.sendAt ? format(new Date(message.sendAt), 'MMM dd, yyyy') : ''}</span>
                                    </div>
                                    <div className='text-sm mt-2'>
                                        <span>{t('annoucementPage.sendBy')} </span> <span className='font-medium'>{(message?.userWhoSendMessage == user.id ? 'Vous' : messageApiService.getUserWhoSendMessage(message, user))}</span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className='my-4'>
                            <div className='line border-1 border-solid border-color-primary'></div>
                        </div>
                    </>
                ))}
            </div>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-VJ0Y9D0VXY"></script>
            <script src='https://soluchange.com/assets/scripts/google_analytic.js'> </script>
        </div>
    );
};

export default MessageAnnouncement;
