import axios from 'axios'


import { url } from './constants'

const authUrl = url + 'api/auth'

axios.defaults.withCredentials = true

const login = async authObj => {
  let response = {}
  await axios
    .post(authUrl + '/login', authObj, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message  }
    })

  return response
}

const register = async account => {
  let response = {}
  await axios
    .post(authUrl + '/register', account, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message  }
    })

  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  register
}
