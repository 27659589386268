import React, { useEffect, useState } from 'react'
import ResultSearchAnnouncementTable from './components/ResultSearchAnnouncementTable';
import Footer from '../../components/footer/Footer';
import currencyAnnouncementApiService from '../../services/currencyAnnouncementApiService';
import RegisterModal from '../../components/auth-modal/RegisterModal';
import LoginModal from '../../components/auth-modal/LoginModal';
import moneyAnnouncementApiService from '../../services/moneyAnnouncementApiService';
import { useNavigate, useOutletContext } from 'react-router-dom';
import AnnouncementCard from '../home/components/AnnouncementCard/AnnounceCard';
import './SearchAnnouncePage.css'
import OtpVerificationModal from '../../components/auth-modal/OtpVerificationCodeModal';
import useWindowSize from '../../components/layout/WindowResize';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import AnnouncementModal from '../../components/announcement-modal/AnnouncementModal';

const SearchAnnouncePage = ({ }) => {
    document.title = "Search Page";

    const [key, setKey] = useState(0); // State to force re-render
    const { t, i18n } = useTranslation();
    const [announcementsAboutMoney, setAnnouncementsAboutMoney] = useState([])
    const [announcementsAboutCurrency, setAnnouncementsAboutCurrency] = useState([])
    const [zone, setZone] = useState({})
    const [currencyModel, setCurrencyModel] = useState({})
    const [moneyModel, setMoneyModel] = useState({})
    const [showCurrencyAnnounce, setShowCurrencyAnnounce] = useState(false);
    const [showAllAnnounce, setShowAllAnnounce] = useState(false);
    const emptyMessageForSearchAnnouncement = "Aucune annonce présente pour le moment. Veuillez cliquer sur le bouton (+) à côté du titre pour laisser une annonce dans la plateforme.";
    const emptyMessageForSearchZone = "Aucune annonce présente pour la zone sélectionnée.";
    const [userLogged, setUserLogged] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showCard, setShowCard] = useState(false)
    const [showRegisterModal, setShowRegisterModal] = useState(false)
    const [user, setUser] = useState({})
    const [showOtpVerificationModal, setShowOtpVerificationModal] = useState(false)
    const [width, height] = useWindowSize();
    const [showAnnouncementModal, setShowAnnouncementModal] = useState(false)
    const [activeIndexTabMenu, setActiveIndexTabMenu] = useState(0);

    const [ownerOfAnnouncementId, setOwnerOfAnnouncementId] = useState('')
    const [announcementId, setAnnouncementId] = useState('')
    const navigate = useNavigate();

    const { showSuccessMessage, showErrorMessage, showInfoMessage, showWarnMessage } = useOutletContext();


    // Listen for language changes and force re-render
    useEffect(() => {
        const handleLanguageChange = () => {
            setKey(prevKey => prevKey + 1); // Increment key to force re-render
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    const initDataView = () => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setUser(user);
            setUserLogged(true);
        }

        if (sessionStorage.getItem('search-by-zone') == 'true') {
            setZone(JSON.parse(sessionStorage.getItem('zone')))
            let result = JSON.parse(sessionStorage.getItem('zones-result'));
            setShowAllAnnounce(true);
            if (result != null) {
                setAnnouncementsAboutMoney([...(result.exchangeMoneyAnnouncements)])
                setAnnouncementsAboutCurrency([...(result.exchangeCurrencyAnnouncements)])
            }
        } else {
            setShowAllAnnounce(false);
            if (sessionStorage.getItem('search-about-money') == 'true') {
                setMoneyModel(JSON.parse(sessionStorage.getItem('moneyModel')) ?? {});
                let result = JSON.parse(sessionStorage.getItem('search-money-announce')) ?? [];
                setShowCurrencyAnnounce(false);
                setAnnouncementsAboutMoney([...(result)])
            } else {
                let result = JSON.parse(sessionStorage.getItem('search-currency-announce')) ?? [];
                setCurrencyModel(JSON.parse(sessionStorage.getItem('currencyModel')) ?? {});
                setShowCurrencyAnnounce(true);
                setAnnouncementsAboutCurrency([...(result)])
            }
        }
    }

    useEffect(() => {
        initDataView();
        setShowCard(true)
    }, [])

    const searchResultCallBack = async (announceModel, announceResult) => {
        // if (showAllAnnounce) {
        //     setAnnouncementsAboutCurrency(announceResult.exchangeMoneyAnnouncements)
        //     setAnnouncementsAboutMoney(announceResult.exchangeCurrencyAnnouncements)
        // } else {
        //     if (showCurrencyAnnounce) {
        //         setCurrencyModel(announceModel)
        //         setAnnouncementsAboutCurrency(announceResult)
        //     } else {
        //         setMoneyModel(announceModel)
        //         setAnnouncementsAboutMoney(announceResult)
        //     }
        // }
        initDataView();
    }


    const writeAboutAnnounce = (annonceId, userOwnerId) => {
        setAnnouncementId(annonceId)
        setOwnerOfAnnouncementId(userOwnerId)

        if (!userLogged) {
            setShowLoginModal(true)
        } else {
            navigate("/message-detail/announce/" + annonceId + "/sender/" + user?.id + "/owner/" + userOwnerId)
        }
    }

    const detailOfSearch = ({ textColor }) => {
        return <div className={textColor + ' ' + (textColor.length > 0 ? '' : 'p-0 ') + 'col-12 ' + (showAllAnnounce ? '' : 'md:col-6')} >
            {
                showAllAnnounce ? (
                    <p className={textColor + 'mt-0 mb-4 md:text-2xl'}>{t('searchPage.searchAllAnnounces')} : <strong>{zone.name}</strong> </p>
                ) : (
                    <div className='mb-4'>
                        {
                            showCurrencyAnnounce ? (
                                <p className={textColor + 'mt-0 mb-2 md:text-2xl'}>{t('searchPage.searchFrom')} : <strong>{currencyModel?.exchangeCity?.name}</strong> </p>
                            ) : (
                                <p className={textColor + 'mt-0 mb-2 md:text-2xl'}>
                                    {t('searchPage.searchFrom')} : <strong>{moneyModel?.cityWhereHeSent?.name}</strong>  {t('global.to')}  <strong>{moneyModel?.cityWhereHeRetrieve?.name}</strong>
                                </p>
                            )
                            // showCurrencyAnnounce ? (
                            //     <p className={textColor + 'mt-0 mb-2 md:text-2xl'}>{t('searchPage.searchLinkToCurrency')} : <strong>{currencyModel?.exchangeCity?.name}</strong> </p>
                            // ) : (
                            //     <p className={textColor + 'mt-0 mb-2 md:text-2xl'}>
                            //         {t('searchPage.searchLinkToMoney')} : <strong>{moneyModel?.cityWhereHeSent?.name}</strong>  {t('global.to')}  <strong>{moneyModel?.cityWhereHeRetrieve?.name}</strong>
                            //     </p>
                            // )
                        }

                        <p className={textColor + ' mt-0 mb-2'} >{(moneyModel?.cityWhereHeSent?.name ? t('searchPage.amountToSent') : t('searchPage.amountToSend'))} <strong>{moneyModel?.cityWhereHeSent?.name ? moneyModel?.amountToSend + ' ' + moneyModel?.currencyFrom : currencyModel?.amountToSend + ' ' + currencyModel?.currencyFrom}</strong></p>
                        <p className={textColor + ' mt-0 mb-2'} >{t('searchPage.amountToReceive')} <strong>{moneyModel?.cityWhereHeSent?.name ? moneyModel?.amountToRetrieve + ' ' + moneyModel?.currencyTo : currencyModel?.amountToRetrieve + ' ' + currencyModel?.currencyTo}</strong></p>
                        <p className={textColor + ' mt-0 mb-2'} >{t('searchPage.rate')} <strong>{moneyModel?.cityWhereHeSent?.name ? moneyModel?.rate : currencyModel?.rate}</strong></p>
                    </div>
                )
            }
        </div>
    }

    return (
        <div className="search-page page flex flex-column min-h-screen" >
            <div className="search-container flex-grow-1">
                <div className='header mb-4'>
                    <div className='toolbar relative'>
                        <div className='absolute z-1  h-full w-full overflow-hidden'>
                            <div className='h-full w-full relative z-5'>
                                <div className='image-container z-1 h-full w-full'>
                                    <div className='image-content h-full w-full'>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-content relative z-5 px-4 md:px-6 '>
                            <div className={(showAllAnnounce ? 'search-for-all-options flex justify-content-center align-items-center' : '') + 'toolbar-content max-width-container mx-auto'} >
                                <div className='grid py-5'>
                                    {
                                        width > 587 ? detailOfSearch({
                                            textColor: 'text-white'
                                        }) : ''
                                    }

                                    {
                                        showAllAnnounce ? (
                                            ''
                                        ) : (
                                            <div className='col-12 md:col-6'>
                                                {showCard ? (
                                                    <AnnouncementCard
                                                        showInfoMessage={({ title, message }) => {
                                                            showInfoMessage({
                                                                title: title, message: message
                                                            });
                                                        }}
                                                        showWarnMessage={({ title, message }) => {
                                                            showWarnMessage({
                                                                title: title, message: message
                                                            });
                                                        }}
                                                        showSuccessMessage={({ title, message }) => {
                                                            showSuccessMessage({
                                                                title: title, message: message
                                                            });
                                                        }}
                                                        showErrorMessage={({ title, message }) => {
                                                            showErrorMessage({
                                                                title: title, message: message
                                                            });
                                                        }}
                                                        searchResultOntheSamePageCallBack={searchResultCallBack}
                                                        showSearchResultOntheSamePage={true}
                                                        announceObject={showCurrencyAnnounce ? currencyModel : moneyModel}
                                                        textFieldClass={'text-white'}
                                                        showIndex={showCurrencyAnnounce ? 1 : 0} />
                                                ) : ('')}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='px-4 md:px-6 pt-0  pb-4 md:pb-6'>
                    {
                        width <= 587 ? detailOfSearch({
                            textColor: ''
                        }) : ''
                    }


                    <div className='max-width-container mx-auto'>
                        {
                            showAllAnnounce ? (
                                <>
                                    <div className='mb-6'>
                                        {
                                            announcementsAboutMoney?.length > 0 ? (
                                                <h4 className='mb-4 text-xl'>{t('searchPage.announceLinkToMoney')} </h4>
                                            ) : ''
                                        }
                                        <ResultSearchAnnouncementTable
                                            writeAboutAnnounce={writeAboutAnnounce}
                                            showCurrencyAnnouncement={false}
                                            showSaveAnnouncementBtn={false}
                                            emptyMessage={emptyMessageForSearchZone}
                                            announcements={announcementsAboutMoney}
                                        />
                                    </div>
                                    <div>
                                        {
                                            announcementsAboutCurrency?.length > 0 ? (
                                                <h4 className='mb-4 text-xl'>{t('searchPage.announceLinkToCurency')}</h4>
                                            ) : ''
                                        }
                                        <ResultSearchAnnouncementTable
                                            writeAboutAnnounce={writeAboutAnnounce}
                                            showCurrencyAnnouncement={true}
                                            showSaveAnnouncementBtn={false}
                                            emptyMessage={emptyMessageForSearchZone}
                                            announcements={announcementsAboutCurrency}
                                        />
                                    </div>
                                </>
                            ) : (
                                showCurrencyAnnounce ? (
                                    <div>
                                        <div className='flex mb-4 justify-content-between align-items-center flex-wrap'>
                                            {
                                                announcementsAboutCurrency?.length > 0 ? (
                                                    <h4 className='m-0 text-xl'>{t('searchPage.announceLinkToCurencyFound')} </h4>
                                                ) : ''
                                            }
                                        </div>
                                        <ResultSearchAnnouncementTable writeAboutAnnounce={writeAboutAnnounce} showCurrencyAnnouncement={true} showSaveAnnouncementBtn={true} emptyMessage={emptyMessageForSearchAnnouncement} activeIndexTabMenu={1} saveAnnouncement={() => {
                                            setActiveIndexTabMenu(1)
                                            if (!userLogged) {
                                                setShowLoginModal(true)
                                            } else {
                                                // saveCurrencyAnnouncement(user.id)
                                                setShowAnnouncementModal(true);
                                            }
                                        }} announcements={announcementsAboutCurrency} />
                                    </div>
                                ) : (
                                    <div className='mb-6'>
                                        <div className='flex mb-4 justify-content-between align-items-center flex-wrap'>
                                            {
                                                announcementsAboutMoney?.length > 0 ? (
                                                    <h4 className='m-0 text-xl'>{t('searchPage.announceLinkToMoney')} </h4>
                                                ) : ''
                                            }


                                        </div>
                                        <ResultSearchAnnouncementTable writeAboutAnnounce={writeAboutAnnounce} showCurrencyAnnouncement={false} showSaveAnnouncementBtn={true} activeIndexTabMenu={2} saveAnnouncement={() => {
                                            setActiveIndexTabMenu(0)
                                            if (!userLogged) {
                                                setShowLoginModal(true)
                                            } else {
                                                // saveMoneyAnnouncement(user.id)
                                                setShowAnnouncementModal(true);
                                            }
                                        }} emptyMessage={emptyMessageForSearchAnnouncement} announcements={announcementsAboutMoney} />
                                    </div>
                                )

                            )
                        }
                    </div>
                </div>
            </div >

            {
                showAnnouncementModal ? <AnnouncementModal
                    showValidityInput={true}
                    hideMenuTabOffer={true}
                    announceObject={activeIndexTabMenu === 0 ? moneyModel : currencyModel}
                    activeIndexTabMenu={activeIndexTabMenu}
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    opentLoginModal={(offer) => {
                        setShowAnnouncementModal(false);
                        setShowRegisterModal(false);
                        setShowLoginModal(true);
                    }}
                    openPublishedModalChoice={(offer) => {
                        setShowAnnouncementModal(false);
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                    }}
                    open={showAnnouncementModal}
                    onClose={() => setShowAnnouncementModal(false)}
                /> : ''
            }


            {
                showLoginModal ? <LoginModal
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    onSubmit={(logUser) => {
                        setUser(logUser);
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        setUserLogged(true);
                        if (announcementId?.length > 3) {
                            navigate("/message-detail/announce/" + announcementId + "/sender/" + logUser?.id + "/owner/" + ownerOfAnnouncementId)
                        } else {
                            if (showCurrencyAnnounce) {
                                // saveCurrencyAnnouncement(logUser.id)
                                setActiveIndexTabMenu(1);
                                setShowAnnouncementModal(true);
                            } else {
                                // saveMoneyAnnouncement(logUser.id)
                                setActiveIndexTabMenu(0);
                                setShowAnnouncementModal(true);
                            }
                        }
                    }} open={showLoginModal} openCreateModal={() => { setShowLoginModal(false); setShowRegisterModal(true); }} onClose={() => setShowLoginModal(false)} /> : ''
            }

            {
                showRegisterModal ? <RegisterModal
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    open={showRegisterModal} onSubmit={() => {
                        // setUser(logUser);
                        // setUserLogged(true);
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        setShowOtpVerificationModal(true);
                    }} openLoginModal={() => { setShowRegisterModal(false); setShowLoginModal(true); }} onClose={() => setShowRegisterModal(false)} /> : ''
            }

            {
                showOtpVerificationModal ? <OtpVerificationModal
                    showInfoMessage={({ title, message }) => {
                        showInfoMessage({
                            title: title, message: message
                        });
                    }}
                    showWarnMessage={({ title, message }) => {
                        showWarnMessage({
                            title: title, message: message
                        });
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    open={showOtpVerificationModal} onSubmit={(logUser) => {
                        setUser(logUser);
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        setUserLogged(true);
                        setShowOtpVerificationModal(false);
                        setShowAnnouncementModal(true);
                    }} onClose={() => setShowOtpVerificationModal(false)} /> : ''
            }
            <Footer />
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-VJ0Y9D0VXY"></script>
            <script src='https://soluchange.com/assets/scripts/google_analytic.js'> </script>
        </div >
    );
}

export default SearchAnnouncePage;
