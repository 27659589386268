import axios from 'axios'


import { url } from './constants'

const messageUrl = url + 'api/message/'
axios.defaults.withCredentials = true

const findByOwnerOfAnnounce = async (userId) => {
  let response = {}
  await axios
    .get(
      messageUrl + 'findByOwnerOfAnnounce/' + userId,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message  }
    })
  return response
}

const findByInterestedUser = async (userId) => {
  let response = {}
  await axios
    .get(
      messageUrl + 'findByInterestedUser/' + userId,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message  }
    })
  return response
}

const getMessageOfDiscussion = async (ownerId,userId, announceId) => {
  let response = {}
  await axios
    .get(
      messageUrl + 'owner/' + ownerId + '/interested/' + userId + '/announce/' + announceId,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message  }
    })
  return response
}

const getMessageByAnnounceIdAndOwnerOfAnnounce = async (announceId,ownerId) => {
  let response = {}
  await axios
    .get(
      messageUrl + 'announce/' + announceId + '/owner/' + ownerId,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      // response = { error: e.response?.data?.message ?? e.message  }
      response = [];
    })
  return response
}

const getUserWhoSendMessage = (message, user) => {
  if (message?.userWhoSendMessage == user?.id) {
      return "vous"
  } else {
      if (message?.userWhoSendMessage == message?.user?.id) {
          return message?.user?.lastname + ' ' + message?.user?.firstname
      } else {
          return message?.ownerOfAnnouncement?.lastname + ' ' + message?.ownerOfAnnouncement?.firstname
      }
  }
}


const sendMessage = async (messageObj) => {
  let response = {}
  await axios
    .post(
      messageUrl + 'send',
      messageObj,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })
  return response
}


const makeReadMessage = async (ids) => {
  let response = {}
  await axios
    .post(
      messageUrl + 'read?ids=' + ids,
      {},
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message ?? e.message }
    })
  return response
}

export default {
  findByOwnerOfAnnounce,
  makeReadMessage,
  sendMessage,
  findByInterestedUser,
  getMessageByAnnounceIdAndOwnerOfAnnounce,
  getMessageOfDiscussion,
  getUserWhoSendMessage
}
