import axios from 'axios'
import qs from 'qs'

import { url } from './constants'

const sharedUrl = url + 'api/share-app'

axios.defaults.withCredentials = true

const createAppLink = async userId => {
  let response = {}
  await axios
    .post(
      sharedUrl + '/create-app-link/' + userId,
      {},
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message }
    })
  return response
}

const checkLink = async (userId, link) => {
  let response = {}
  await axios
    .post(sharedUrl + '/check-link/' + userId + '/' + link, {
      withCredentials: true
    })
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message }
    })
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  checkLink,
  createAppLink
}
