import axios from 'axios'


import { url } from './constants'

const announcementUrl = url + 'api/announcement/'

const searchAnnoucement = async (announcementObj) => {
  let response = {}
  await axios
    .post(
      announcementUrl + 'findByZone',
      announcementObj,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message }
    })
  return response
}


const getAnnounceById = async (id) => {
  let response = {}
  await axios
    .get(
      announcementUrl + '' + id,
      {
        withCredentials: true
      }
    )
    .then(res => {
      response = res.data
    })
    .catch(e => {
      response = { error: e.response?.data?.message  ?? e.message  }
    })
  return response
}

export default {
  getAnnounceById,
  searchAnnoucement,
}
