import React, { useEffect, useState } from 'react'
import { Avatar } from 'primereact/avatar';
import { useNavigate, Link } from 'react-router-dom';
import '../Profil.css';
import Cookies from 'js-cookie';
import commentApiService from '../../../services/commentApiService';
import { useTranslation } from 'react-i18next';

const SidebarComponent = ({ className }) => {
    const [logUser, setLogUser] = useState({
        id: ""
    });
    const [rating, setRating] = useState(0);
    const navigate = useNavigate();

    const cookieName = "accessToken";

    const { t } = useTranslation();

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setLogUser(user);
            getUserRating(user);
        }
    }, [])

    const getUserRating = async (userId) => {
        let response = await commentApiService.getRatingById(userId);
        setRating(response);
    }


    const getCookie = () => {
    };

    const removeCookie = () => {
        Cookies.remove(cookieName, { path: '/', domain: 'localhost' });
    };



    return (
        <div className={className + ' sidebar'}>
            <div className='flex justify-content-center flex-column align-items-center text-center'>
                <Avatar image="/assets/images/001-man.svg" className="w-6rem h-6rem mb-4" size="xlarge" shape="circle"  />
                {/* <Rating value={rating} readOnly cancel={false} /> */}
            </div>

            <ul className='mt-4 list-none p-0'>
                <li className='text'>
                    <Link to="/profil/update" className={"text-lg text no-underline border-none link " + (
                        window.location.pathname.indexOf('/profil/update') > -1 ? 'active' : ''
                    )}>{t("sidebar.menu.profil.update")}</Link>
                </li>
                {/* <li className='text'>
                    <Link to="/profil/points" className={"text-lg text no-underline border-none link " + (
                        window.location.pathname.indexOf('/profil/points') > -1 ? 'active' : ''
                    )}>Mon point</Link>
                </li> */}
                {/* <li className='text'>
                    <Link to="/profil/comments" className={"text-lg text no-underline border-none link " + (
                        window.location.pathname.indexOf('/profil/comments') > -1 ? 'active' : ''
                    )}>Commentaires</Link>
                </li> */}
                <li className='text'>
                    <Link to="/profil" className={"text-lg text no-underline border-none link " + (
                        window.location.pathname.indexOf('/profil/announces') > -1 ? 'active' : ''
                    )}>{t('sidebar.menu.myAnnounces')}</Link>
                </li>
                {/* <li className='text'>
                    <Link to="/faq" className={"text-lg text no-underline border-none link " + (
                        window.location.pathname.indexOf('/faq') > -1 ? 'active' : ''
                    )}>{t('sidebar.menu.help')}</Link>
                </li> */}
            </ul>
        </div>
    );
}

export default SidebarComponent;
